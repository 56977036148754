import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { Button } from "react-bootstrap"

const SeModal = ({show,setShow}) =>{
    return(
        <Dialog
          disableEscapeKeyDown
          open={show}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Study Essential Mode</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className="mb-0"><strong>Study Essential Mode:</strong> Curated study questions and notes tailored for exam preparation. Access filtered and revised questions on potential exam topics for effective and focused learning</span>
            </DialogContentText>
            <DialogActions>
              <Button onClick={setShow} color="primary" >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
    )
}

export default SeModal;