import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessModal({ setOpen, open }) {
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div class="thankyou-page">
          <div class="_header">
            <div class="logo">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/medicalapp-ad2fc.appspot.com/o/success-page%2FPayment%20Success%20Medrevisions.svg?alt=media&token=390f2563-eec8-4697-85e3-5d65686be40f"
                alt=""
              />
            </div>
            <h1>Payment Successful!</h1>
          </div>
          <div class="_body">
            <div style={{ width: "50%", textAlign: "center", margin: "auto" }}>
              <h2 style={{ textAlign: "center", fontWeight: 300 }}>
                Thank you for subscribing to our plan. You can access all our
                features now.
              </h2>
            </div>
          </div>
          <div class="_footer">
            <a class="btn" href="/">
              Go to dashboard
            </a>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

// <!DOCTYPE html>
// <html lang="en">
//   <head>
//     <link
//       href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
//       rel="stylesheet"
//       id="bootstrap-css"
//     />
//     <!-- <link href="/custom.css" rel="stylesheet" id="custom-css"> -->
//     <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
//     <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
//     <!------ Include the above in your HEAD tag ---------->
//     <meta charset="UTF-8" />
//     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
//     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//     <title>Payment Success</title>
//     <style>

//     </style>
//   </head>
//   <body>

//   </body>
// </html>
