import React from "react";

import { Paper, Container, Typography } from "@material-ui/core";
import Footer from "../../components/Footer";
import "./index.scss";

const Privacy = () => {
  return (
    <>
      <Container>
        <Paper elevation={1} className="terms-privacy-wrapper">
          <Typography variant="h3" gutterBottom>
            Privacy policy
          </Typography>
          <br />
          <br />

          <Typography>
            This privacy policy sets out how Medrevisions Inc uses and protects
            any information that you give when you use the website.
            <br />
            Your privacy is very important to us and we will never sell your
            email address or any other personal details to third parties.
            <br />
            When you make a purchase from the website we collect and store the
            following information:
            <ul>
              <li>Your email address</li>
              <li>Your name</li>
              <li>Your address</li>
              <li>Purchase Information</li>
            </ul>
            <br />
            Once you start using the website we also collect the following
            information:
            <ul>
              <li>The history of the questions you've answered</li>
              <li>Any comments you've made about the questions</li>
              <li>Any messages or feedback you've sent to the admin team</li>
              <li>
                Details of when you login including the time/date and IP address
              </li>
            </ul>
            <br />
            <br />
            All this information is stored on our secure servers. We don't have
            access to, collect or store any credit/debit card details. The
            payment system is provided by Stripe and is separate from the
            website.
            <br />
            <br />
            We have a policy of limiting e-mail communication to a minimum, but
            we may send a subscriber an email to welcome new subscribers, answer
            specific queries about the site (including reminding a user of their
            password), to answer specific queries regarding a question, your
            progress in passing the exam and the development of the website etc.
            <br />
            <br />
            If Medrevisions Inc believes that your use of the site is unlawful
            or damaging to other users, we reserve the right to disclose the
            information obtained about you to the extent that it is reasonably
            necessary in our opinion to remedy the situation and prevent such
            conduct continuing.
            <br />
            <br />
            Your IP address may be stored and used to help identify you and to
            gather broad demographic information about you for statistical
            purposes. We may also use your IP address in helping to diagnose
            problems with our server. Your IP address may also be used to assist
            in the detection of fraud and we may pass this information to the
            police
            <br />
            <br />
            It is your right to have any information that we store about you
            erased. You can do this by emailing us at contact@medrevisions.com
          </Typography>
          <br />
        </Paper>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default Privacy;
