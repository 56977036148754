import React from "react";
import { Typography, Paper, CircularProgress } from "@material-ui/core";

import ReactApexChart from "react-apexcharts";

const ProgresH = ({ submissions, topics, subTopics }) => {
  // const { submissions, topics } = props;

  const labels = Object.values(subTopics).map((t) => t.name);
  let data = {};
  const correct = [];
  const wrong = [];
  const unanswered = [];

  if (labels.length > 0) {
    Object.keys(subTopics || {}).map((subTopicId) => {
      // if (subTopicId !== "lastAttempted") {
      let c = 0;
      let w = 0;
      let t = Object.keys(topics["internalmedicine"]?.questions || {}).length;
      Object.keys(submissions["internalmedicine"] || {}).map((questionId) => {
        if (
          submissions["internalmedicine"][questionId]["subTopicID"] ===
          subTopicId
        ) {
          if (submissions["internalmedicine"][questionId].correct) {
            c += 1;
          } else {
            w += 1;
          }
        }
      });

    
      correct.push(c);
      wrong.push(w);
      unanswered.push(Math.floor(t - c - w));
      // }
    });

  
  }

  const [isShow, setShow] = React.useState(false);
  const _state = {
    series: [
      {
        name: "Correct Answers",
        data: correct,
      },
      {
        name: "Wrong Answers",
        data: wrong,
      },
      // {
      //   name: "Unanswered",
      //   data: unanswered,
      // },
    ],

    options: {
      // colors: ["#00e3969c", "#ff456094", "#ffc10785"],
      colors: ["#00e3969c", "#ff456094"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        events: {
          // click: function (event, chartContext, config) {
          //   // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
          // },
          // dataPointSelection: (event, chartContext, config) => {

          // },
          click(event, chartContext, config) {
            if (
              config.config.xaxis.categories[config.dataPointIndex] ===
              "Internal Medicine"
            ) {
              setShow(!isShow);
              // onClickChart(isShow);
            }
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      yaxis: {
        tickAmount: 10,
        max: 100,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      xaxis: {
        type: "category",
        categories: labels,
      },

      legend: {
        position: "top",
        offsetX: 40,
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        style: {},
      },
    },
  };
  return (
    <>
      {/* <Paper className="dashbaord-container progress"> */}

      <div className="">
        {labels.length > 0 ? (
          // <HorizontalBar
          //   data={data}
          //   width={100}
          //   height={250}
          //   options={{
          //     maintainAspectRatio: false,
          //     title: {
          //       display: true,
          //       text: "Percentage (%) Complete",
          //     },
          //     scales: {
          //       xAxes: [{ stacked: true }],
          //       yAxes: [{ stacked: true }],
          //     },
          //   }}
          // />
          <ReactApexChart
            options={_state.options}
            series={_state.series}
            type="bar"
            height={350}
          />
        ) : (
          <>
            <CircularProgress />
            <Typography variant="subtitle1" color="textSecondary">
              Building your progress Graph
            </Typography>
          </>
        )}
      </div>
      {/* </Paper> */}
    </>
  );
};
export default ProgresH;
