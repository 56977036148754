import React from "react";
import { Typography, Paper, CircularProgress } from "@material-ui/core";

import ReactApexChart from "react-apexcharts";
const ProgresH = ({ onClickChart, submissions, topics }) => {

  const labels = Object.values(topics).map((t) => t.name);
  let data = {};
  const correct = [];
  const wrong = [];
  const unanswered = [];

  if (labels.length > 0) {
    Object.keys(topics || {}).map((topicId) => {
      if (topicId !== "lastAttempted") {
        let c = 0;
        let w = 0;
        let t = Object.keys(topics[topicId].questions || {}).length;
        Object.keys(submissions[topicId] || {}).map((questionId) => {
          if (submissions[topicId][questionId].correct) {
            c += 1;
          } else {
            w += 1;
          }
        });


        correct.push(Math.floor((c / t) * 100));
        wrong.push(Math.floor((w / t) * 100));
        const cp = Math.floor((c / t) * 100);
        const wp = Math.floor((w / t) * 100);
        // const tp = Math.floor((w / t) * 100);
        return unanswered.push(100 - cp - wp);
        // return unanswered.push(Math.floor(((t - c - w) / t) * 100));
      }
    });

    // data = {
    //   labels: labels,
    //   options: {
    //     scales: {
    //       xAxes: [{ stacked: true }],
    //       yAxes: [{ stacked: true }],
    //     },
    //   },
    //   datasets: [
    //     {
    //       label: "Correct Answers",
    //       data: correct,
    //       backgroundColor: "rgba(75, 192, 192, 0.2)",
    //       borderColor: "rgba(75, 192, 192, 1)",
    //       borderWidth: 1,
    //     },
    //     {
    //       label: "Wrong Answers",
    //       data: wrong,
    //       backgroundColor: "rgba(255, 99, 132, 0.2)",
    //       borderColor: "rgba(255, 99, 132, 1)",
    //       borderWidth: 1,
    //     },
    //     {
    //       label: "Unanswered",
    //       data: unanswered,
    //       backgroundColor: "#feb01982",
    //       borderColor: "#feb",
    //       borderWidth: 1,
    //     },
    //   ],
    // };
  }

  const [isShow, setShow] = React.useState(false);
  const _state = {
    series: [
      {
        name: "Correct Answers",
        data: correct,
      },
      {
        name: "Wrong Answers",
        data: wrong,
      },
      {
        name: "Unanswered",
        data: unanswered,
      },
    ],

    options: {
      colors: ["#00e3969c", "#ff456094", "#ffc10785"],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        events: {
          // click: function (event, chartContext, config) {
          //   // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
          // },
          // dataPointSelection: (event, chartContext, config) => {

          // },
          click(event, chartContext, config) {
            if (
              config.config.xaxis.categories[config.dataPointIndex] ===
              "Internal Medicine"
            ) {
              setShow(!isShow);
              onClickChart(isShow);
            }
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        max: 100,
        min: 0,
        labels: {
          formatter: function (value) {
            return value + " %";
          },
        },
        showForNullSeries: true,
      },
      xaxis: {
        type: "category",
        categories: labels,
      },

      legend: {
        position: "top",
        offsetX: 40,
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        style: {},
      },
    },
  };
  return (
    <>
      {/* <Paper className="dashbaord-container progress"> */}

      <div className="">
        {labels.length > 0 ? (
          // <HorizontalBar
          //   data={data}
          //   width={100}
          //   height={250}
          //   options={{
          //     maintainAspectRatio: false,
          //     title: {
          //       display: true,
          //       text: "Percentage (%) Complete",
          //     },
          //     scales: {
          //       xAxes: [{ stacked: true }],
          //       yAxes: [{ stacked: true }],
          //     },
          //   }}
          // />
          <div id="chart">
            <ReactApexChart
              options={_state.options}
              series={_state.series}
              type="bar"
              height={350}
            />
          </div>
        ) : (
          <>
            <CircularProgress />
            <Typography variant="subtitle1" color="textSecondary">
              Building your progress Graph
            </Typography>
          </>
        )}
      </div>
      {/* </Paper> */}
    </>
  );
};
export default ProgresH;
