import React, { useEffect, useState, useContext } from "react";

import { firestore, firebase } from "../../../firebase";
import ReferralImg from "../../../images/referral.png";

import { Col, Row } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  LineShareButton,
  EmailShareButton,
} from "react-share";
import paidIcon from "../../../paid.svg";
import AuthContext from "../../../context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table, Pagination } from "react-bootstrap";

import { Link } from "react-router-dom";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  setRef,
} from "@material-ui/core";
function createData({ refFor, date, credit }) {
  return {
    email: refFor,
    time: new Date(date.seconds * 1000).toDateString(),
    amount: credit,
  };
}

function createPayoutData({
  payPalEmail,
  requestedOn,
  payoutProcessedOn,
  amount,
}) {
  return {
    requestedOn: new Date(requestedOn.seconds * 1000).toDateString(),
    payoutProcessedOn: payoutProcessedOn
      ? new Date(payoutProcessedOn.seconds * 1000).toDateString()
      : "Pending",
    payPalEmail,
    amount,
  };
}
const Revision = (props) => {
  const [dontShowFlag, setDontShowFlag] = useState(false);
  const [user, setUser] = useState({});
  const [dialog, setDialog] = useState(false);
  const { userType } = useContext(AuthContext);
  const [credits, setCredits] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [totalPayouts, setTotalPayouts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [payPalEmail, setPayPalEmail] = useState(null);
  const [payPalEmailConf, setPayPalEmailConf] = useState(null);

  const { id, refToken } = user;

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();
        fetchRefferalCredits(user.uid);
        setUser({
          ...userSnap.data(),
          id: userSnap.id,
        });
      } else {
        // User not logged in or has just logged out.
        // window.location.href = "/auth";
      }
    });
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();

        setUser({
          ...userSnap.data(),
          id: userSnap.id,
        });
      } else {
        // User not logged in or has just logged out.
      }
    });
  }, []);
  const setDontShow = () => {
    setDontShowFlag(true);
    window.localStorage.setItem("dontShowFlag", true);
  };
  const shareLink = `https://www.medrevisions.com/?ref=${user.refToken}`;
  // const shareLink = `${window.location.origin}?ref=${user.refToken}`;
  const shareText = `I'm using Medrevisions.com to prepare for my PLAB exams.It's very usefull Use this referral link to get a 10% discount!`;

  const fetchTrans = () => {
    firestore
      .collection("referrals")
      .doc(id)
      .collection("credits")
      .get()
      .then((snap) => {
        const credits = [];
        let bal = 0;
        snap.forEach((s) => {
          const data = s.data();
          credits.push(createData(data));
          bal += data.credit;
        });
        setCredits(credits);

        firestore
          .collection("referrals")
          .doc(id)
          .collection("payouts")
          .get()
          .then((snap) => {
            const payouts = [];
            snap.forEach((s) => {
              const data = s.data();
              payouts.push(createPayoutData(data));
              bal -= data.amount;
            });
            setPayouts(payouts);
            setBalance(bal);
          });
      });
  };

  const requestPayout = (e) => {
    e.preventDefault();
    setLoading(true);
    firestore
      .collection("referrals")
      .doc(id)
      .collection("payouts")
      .add({
        requestedOn: firebase.firestore.FieldValue.serverTimestamp(),
        amount: balance,
        payPalEmail,
      })
      .then(() => {
        setLoading(false);
        fetchTrans();
      });
  };

  const handleClose = () => {
    setDialog(false);
  };

  const fetchRefferalCredits = (id) => {
    firestore
      .collection("referrals")
      .doc(id)
      .collection("credits")
      .get()
      .then((snap) => {
        const credits = [];
        let bal = 0;
        snap.forEach((s) => {
          const data = s.data();
          bal += Number(data.credit);
          credits.push(createData(data));
        });
        setCredits(credits);

        firestore
          .collection("referrals")
          .doc(id)
          .collection("payouts")
          .get()
          .then((snap) => {
            let totalPayouts = 0;
            const payouts = [];
            snap.forEach((s) => {
              const data = s.data();
              payouts.push(createPayoutData(data));
              bal -= Number(data.amount);
              totalPayouts += Number(data.amount);
            });
            setPayouts(payouts);
            setBalance(bal);
            setTotalPayouts(totalPayouts);
          });
      });
  };
  const totalEarn = totalPayouts + balance;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <>
      <div className="card">
        <div className="card-header d-none d-sm-block d-xl-none d-md-none">
          <h4 className="card-title"> Referral</h4>
        </div>

        <div className="card-body pb-0">
          {userType === "demo_user" ? (
            <div
              className="d-flex align-items-center justify-content-center flex-column p-2"
              // style={{ height: "50vh" }}
            >
              <img src={paidIcon} />
              <p className="mt-4">
                This feature is disabled in the demo. You need to sign up for a
                plan to access this.
              </p>
              <a
                type="submit"
                className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
                href={`/auth#pricing`}
              >
                {" "}
                Subscribe to a plan
              </a>
              <a
                type="submit"
                className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                href={`/demo/plab`}
              >
                {" "}
                See sample questions
              </a>
            </div>
          ) : (
            <>
              <Row>
                <Col md={8} lg={8} sm={12}>
                  <p>Hello Doctor,</p>
                  <p>
                    You can use your unique referral link to earn
                    <strong>
                      &nbsp;US$5 every time someone signs up using your link
                    </strong>
                    . They will receive a 10% discount!
                  </p>

                  <p>
                    <a href={shareLink}>
                      {" "}
                      Your referral link:&nbsp; {shareLink}
                    </a>
                    <span>
                      <CopyToClipboard
                        text={shareLink}
                        onCopy={() => setCopied(true)}
                      >
                        <i
                          class="lab las la-clipboard pl-1 c-pointer"
                          style={{ fontSize: "1.6rem" }}
                        ></i>
                      </CopyToClipboard>

                      {copied ? (
                        <span style={{ color: "#336acf" }}>Copied.</span>
                      ) : null}
                    </span>
                  </p>
                  <p>
                    <strong>
                      Even after your subscription ends you will be able to use
                      the referral program to earn referral credits.&nbsp;
                    </strong>
                    10 years after you used our platform?! No problem!! you can
                    still refer and earn credits.
                  </p>
                  <p>
                    We have this referral program to help you earn your money
                    back. Please do not misuse this service. When you share your
                    referral link ensure you let the person whom you are
                    recommending our platform to why you like our platform and
                    how it helped you to prepare for your PLAB 1 exam and ace
                    it. This will help others understand how to use our platform
                    effectively for their preparation and subscribe with your
                    referral link.
                  </p>
                </Col>
                <Col md={4} lg={4} sm={12}>
                  <img
                    style={{
                      height: "260px",
                      objectFit: "contain",
                    }}
                    className="d-block w-100 rounded "
                    src={ReferralImg}
                    alt={`Ref`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <p>
                      Ways you can use our referral program to earn passively
                      after you pass your exam.
                    </p>
                    <ol class="r-ol">
                      <li aria-level="1">
                        You can make a youtube video about your PLAB 1 exam and
                        discuss how helpful was MedRevisions for your exam
                        preparation. In the video, you can talk about your
                        referral link and add it to the description of the
                        video.
                      </li>
                      <li aria-level="1">
                        You write a blog article and discuss how helpful was
                        MedRevisions for your exam preparation and include your
                        referral link in the article.
                      </li>
                      <li aria-level="1">
                        In social media for example WhatsApp, Telegram or
                        Facebook groups you can help fellow PLAB 1 candidates by
                        sharing your exam preparation experience with
                        MedRevisions. You can provide them with the referral
                        link to help them avail themselves a 10% discount while
                        you earn 5 USD
                      </li>
                    </ol>
                    <p className="">
                      Click on a social media below for quick share
                    </p>
                    <div className="d-flex pb-2" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                      <FacebookShareButton
                        url={shareLink}
                        className="button-link"
                        quote={shareText}
                      >
                        <IconButton>
                          <i
                            class="lab la-facebook-square"
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </FacebookShareButton>

                      <LinkedinShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="lab la-linkedin"
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </LinkedinShareButton>

                      <TwitterShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                        hashtags={["medrevisions"]}
                      >
                        <IconButton>
                          <i
                            class="lab la-twitter-square"
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </TwitterShareButton>

                      <TelegramShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="lab la-telegram-plane "
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </TelegramShareButton>

                      <WhatsappShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="lab la-whatsapp-square "
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </WhatsappShareButton>

                      <RedditShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="lab la-reddit-square "
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </RedditShareButton>

                      <LineShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="lab la-line "
                            style={{ fontSize: "1.8rem" }}
                            // style={{ color: "#00B900" }}
                          ></i>
                        </IconButton>
                      </LineShareButton>

                      <EmailShareButton
                        url={shareLink}
                        title={shareText}
                        className="button-link"
                      >
                        <IconButton>
                          <i
                            class="las la-envelope-square "
                            style={{ fontSize: "1.8rem" }}
                          ></i>
                        </IconButton>
                      </EmailShareButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
      {userType !== "demo_user" && (
        <>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-success text-success">
                      <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Balance </p>
                      <h4 className="mb-0"> {balance}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-warning">
                      <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                        ></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">Total Payouts </p>
                      <h4 className="mb-0">{totalPayouts}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-danger text-danger">
                      <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        ></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1"> Total Earn</p>
                      <h4 className="mb-0">{totalEarn}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
          <div className="card">
            <div className="card-body">
              <div className="card-header">
                <h4 className="card-title"> Credits</h4>
                <button
                  className=" btn btn-outline-info btn-sm btn-rounded"
                  onClick={() => setDialog(true)}
                >
                  Request Payouts
                </button>
              </div>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Customer Email</th>
                    <th>Date</th>
                    <th className="text-right">Credit Amount (US$)</th>
                  </tr>
                </thead>
                <tbody>
                  {credits?.length > 0 ? (
                    credits.map((row, r) => (
                      <tr key={r}>
                        <td>{row.email}</td>
                        <td>{row.time}</td>
                        <td>{row.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="4" className="text-center mt-4">
                        <p className="mt-4">No Credit to Display</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div> */}
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Payouts</h4>
                {/* {balance <= 20 && ( */}
                  <button
                    className=" btn btn-outline-info btn-sm btn-rounded"
                    onClick={() => setDialog(true)}
                  >
                    Request Payouts
                  </button>
                {/* )} */}
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div id="job_data" className="dataTables_wrapper ">
                    <table
                      className="display w-100 dataTable "
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            style={{ width: "177px" }}
                          >
                            Customer Email
                          </th>
                          <th className="sorting" style={{ width: "278px" }}>
                            Requested Date
                          </th>
                          <th className="sorting" style={{ width: "278px" }}>
                            ProcessedOn Date
                          </th>
                          <th className="sorting" style={{ width: "128px" }}>
                            Credit Amount (US$)
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {payouts?.length > 0 ? (
                          payouts.map((row, r) => (
                            <tr className="odd" role="row">
                              <td>{row.payPalEmail}</td>
                              <td>{row.requestedOn}</td>
                              <td>{row.payoutProcessedOn}</td>
                              <td>{row.amount}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colspan="4" className="text-center mt-4">
                              <p className="mt-4">No payouts to Display</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Credits</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div id="job_data" className="dataTables_wrapper ">
                    <table
                      className="display w-100 dataTable "
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            style={{ width: "177px" }}
                          >
                            Customer Email
                          </th>
                          <th className="sorting" style={{ width: "278px" }}>
                            Date
                          </th>
                          <th className="sorting" style={{ width: "128px" }}>
                            Credit Amount (US$)
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {credits?.length > 0 ? (
                          credits.map((row, r) => (
                            <tr className="odd" role="row">
                              <td>{row.email}</td>
                              <td>{row.time}</td>
                              <td>{row.amount}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colspan="4" className="text-center mt-4">
                              <p className="mt-4">No Credit to Display</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog open={dialog} onClose={handleClose}>
            <form onSubmit={requestPayout}>
              <DialogTitle>{"Referral Account"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <strong>
                    <>
                      <a href={`https://www.medrevisions.com/?ref=${refToken}`}>
                        Referral Link:
                        {` — https://www.medrevisions.com/?ref=${refToken}`}
                      </a>
                    </>
                  </strong>
                </DialogContentText>

                <Typography variant="h6">
                  Paypal Account Information - Balance: US$ {balance}
                </Typography>
                <Typography variant="subtitle2">
                  You can request a payout to your paypal account when you have
                  a balance of greater than US$ 20
                </Typography>

                <TextField
                  required
                  label="PayPal Email"
                  margin="normal"
                  type="email"
                  onChange={(e) => setPayPalEmail(e.target.value)}
                />
                <br />
                <TextField
                  required
                  label="Confirm PayPal Email"
                  margin="normal"
                  type="email"
                  onChange={(e) => setPayPalEmailConf(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button
                  onClick={handleClose}
                  color="primary"
                  disabled={
                    payPalEmail === null ||
                    payPalEmail !== payPalEmailConf ||
                    balance < 20 ||
                    loading
                  }
                  type="submit"
                >
                  Transfer to Paypal
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
    </>
  );
};
export default Revision;
