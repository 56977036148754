import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import withRoot from "./withRoot";
import { withStyles } from "@material-ui/core/styles";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
const styles = theme => ({});
const queryString = require("query-string");
const AppWithRoot = withRoot(withStyles(styles)(App));
// const parsed = queryString.parse(window.location.search);



const refCookie = document.cookie
  .split("; ")
  .filter((cookie) => cookie.match(/ref=/))[0];
const parsed = queryString.parse(refCookie);


if (parsed.ref) {
  window.localStorage.setItem("ref", parsed.ref);
}


ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <AppWithRoot />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.scss";
// import App from "./App";
// import withRoot from "./withRoot";
// import { withStyles } from "@material-ui/core/styles";
// import * as serviceWorker from "./serviceWorker";
// const queryString = require("query-string");

// const styles = theme => ({});

// const AppWithRoot = withRoot(withStyles(styles)(App));
// const parsed = queryString.parse(window.location.search);

// if (parsed.ref) {
//   window.localStorage.setItem("ref", parsed.ref);
// }

// ReactDOM.render(<AppWithRoot />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

