import React, { Fragment, useState, useEffect, useContext } from "react";
import PageTitle from "../../layouts/PageTitle";

import {
  Col,
  Badge,
  // Button,
  Row,
  Accordion,
  Card,
  Tabs,
  Tab,
  Nav,
} from "react-bootstrap";
import MetisMenu from "metismenujs";
import Lottie from "react-lottie";
import loadingStethoscope from "../../56363-stethoscope-doctor.json";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Popover } from "react-tiny-popover";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
} from "@material-ui/core";
import { firestore, firebase } from "../../../firebase";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import DeleteIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import swalMessage from "@sweetalert/with-react";
/// images
import { Link } from "react-router-dom";
import paidIcon from "../../../paid.svg";
import AuthContext from "../../../context";
import { object } from "yup";

const Bookmark = () => {
  const { userType } = useContext(AuthContext);
  const [bookmarks, setBookmarks] = useState({});
  const [_bookmarks, _setBookmarks] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userType !== "") {
      if (userType !== "demo_user" || userType === "expaird_user") {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            firestore
              .collection("bookmarks")
              // .orderBy("timestamp", "desc")
              .doc(user.uid)
              .get()
              .then((bookmarksSnap) => {
                let finalObj = {};
                const _data = bookmarksSnap.data();
                _data &&
                  Object.keys(_data).forEach((k) => {
                    if (_data[k]?.topic !== "Internal Medicine") {
                      if (!finalObj[_data[k].topic]) {
                        finalObj[_data[k].topic] = [];
                      }
                      finalObj[_data[k].topic].push({ ..._data[k], id: k });
                    } else {
                      const title = `${_data[k].subTopic}-${_data[k].topic}`;
                      if (!finalObj[title]) {
                        finalObj[title] = [];
                      }
                      finalObj[title].push({ ..._data[k], id: k });
                    }
                  });

                _setBookmarks(bookmarksSnap.data());
                setBookmarks(finalObj); //to display in ui
                // setBookmarks(finalObj);
                // setBookmarks(bookmarksSnap.data());
                setLoading(false);
              });
          }
        });
      }
    }
  }, []);
  const [bookMarksToDelete, setDeleteBookMarks] = useState({});
  const deleteBookmark = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const _clonedBookmarks = Object.assign({}, bookmarks);
        Object.keys(bookMarksToDelete || {}).forEach((_topic) => {
          // eslint-disable-next-line no-unused-expressions
          bookMarksToDelete[_topic]?.forEach((id) => {
            const index = _clonedBookmarks[_topic].findIndex(
              (b) => b.id === id
            );
            if (index > -1) {
              if (_clonedBookmarks[_topic]) {
                _clonedBookmarks[_topic].splice(index, 1);
                if (_clonedBookmarks[_topic].length === 0) {
                  delete _clonedBookmarks[_topic];
                }
                delete _bookmarks[id]; //
              }
            }
          });
        });
        firestore
          .collection("bookmarks")
          .doc(user.uid)
          .set({ ..._bookmarks })
          .then(() => {
            notifyInfo();
            setBookmarks({ ..._clonedBookmarks });
            _setBookmarks({ ..._bookmarks });
            setRemoveBookMark(false);
            setDeleteBookMarks({});
          });
        // const index = _clonedBookmarks[topic].findIndex((b) => b.id === id);
        // if (index > -1) {
        //   if (_clonedBookmarks[topic]) {
        //     _clonedBookmarks[topic].splice(index, 1);
        //     if (_clonedBookmarks[topic].length === 0) {
        //       delete _clonedBookmarks[topic];
        //     }
        //     delete _bookmarks[id]; //
        //     firestore
        //       .collection("bookmarks")
        //       .doc(user.uid)
        //       .set({ ..._bookmarks })
        //       .then(() => {
        //         notifyInfo();
        //         setBookmarks({ ..._clonedBookmarks });
        //         _setBookmarks({ ..._bookmarks });
        //       });
        //   }
        // }

        // delete bookmarks[id];
        // firestore
        //   .collection("bookmarks")
        //   .doc(user.uid)
        //   .set({ ...bookmarks })
        //   .then(() => {
        //     setBookmarks({ ...bookmarks });
        //   });
      }
    });
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRemove, setRemoveBookMark] = useState(false);

  const [popoverId, setPopoverId] = useState("");
  const [trigger, setTrigger] = useState(false);

  const bookmarkIds = Object.keys(bookmarks || {});
  const bookmarkTopics = Object.keys(bookmarks || {});
  const notifyInfo = () => {
    toast.success("Bookmark deleted!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      icon: true,
      theme: "light",
      progress: undefined,
    });
  };
  const handleRemoveBookMarks = (topic, id) => {
    const _cloneBookMarksToDelete = bookMarksToDelete;
    // Object.assign(bookMarksToDelete || {});
    if (_cloneBookMarksToDelete[topic]?.includes(id)) {
      const filterdIds = _cloneBookMarksToDelete[topic].filter(
        (_id) => _id !== id
      );
      _cloneBookMarksToDelete[topic] = filterdIds;
      setDeleteBookMarks(_cloneBookMarksToDelete);
    } else {
      if (Array.isArray(_cloneBookMarksToDelete[topic])) {
        _cloneBookMarksToDelete[topic].push(id);
      } else {
        _cloneBookMarksToDelete[topic] = [id];
      }
      setDeleteBookMarks(_cloneBookMarksToDelete);
    }
    setTrigger((prevState) => !prevState);
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {userType === "demo_user" || userType === "expaird_user" ? (
        <div className="card">
          <div
            className="d-flex align-items-center justify-content-center flex-column p-2"
            // style={{ height: "50vh" }}
          >
            <img src={paidIcon} />
            <p className="mt-4">
              This feature is disabled in the demo. You need to sign up for a
              plan to access this.
            </p>
            <a
              type="submit"
              className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
              href={`/auth#pricing`}
            >
              {" "}
              Subscribe to a plan
            </a>
            <a
              type="submit"
              className="btn btn-outline-info  btn-rounded btn-sm mt-2"
              href={`/demo/plab`}
            >
              {" "}
              See sample questions
            </a>
          </div>
        </div>
      ) : (
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <div className="card-header d-none d-sm-block d-xl-none d-md-none">
                  <h4 className="card-title"> Bookmarks</h4>
                </div>
                <div className="d-flex align-items-center justify-content-end flex-wrap">
                  <button
                    className={`mr-2  btn-sm btn ${
                      !isRemove ? "btn-outline-warning" : "btn-outline-dark "
                    } btn-rounded`}
                    onClick={() => {
                      if (isRemove) {
                        setRemoveBookMark(false);
                        setDeleteBookMarks({});
                      } else {
                        setRemoveBookMark(true);
                      }
                    }}
                    type="button"
                  >
                    {!isRemove ? "Remove bookmarks" : "Reset"}
                  </button>
                  {!isRemove ? (
                    <button
                      className=" btn btn-info btn-sm btn-rounded m-2"
                      onClick={() =>
                        swal({
                          title: "What do you want to revise ?",

                          buttons: {
                            notes: {
                              text: "Notes",
                              value: "notes",
                              className: "bg-dark",
                            },
                            questions: {
                              text: "Questions",
                              value: "questions",
                              className: "bg-info",
                            },
                          },
                        }).then((v) => {
                          if (v === "questions") {
                            window.location = "/bookmarks?show=questions";
                          } else if (v === "notes") {
                            window.location = "/bookmarks?show=notes";
                          }
                        })
                      }
                      type="button"
                    >
                      Revise All Questions
                    </button>
                  ) : (
                    <button
                      disabled={Object.keys(bookMarksToDelete).length < 1}
                      className=" btn btn-danger btn-sm btn-rounded m-2"
                      onClick={() =>
                        swal({
                          title: "Are Your sure?",
                          icon: "warning",
                          text: "Once deleted, you will not be able to recover this bookmarks!",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => willDelete && deleteBookmark())
                      }
                      type="button"
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    {loading ? (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    ) : bookmarks ? (
                      <PerfectScrollbar
                        style={{
                          // height: "58vh",
                          paddingRight: "1rem",
                        }}
                        id="DZ_W_Todo3"
                        className="widget-media dz-scroll  ps ps--active-y"
                      >
                        {bookmarkTopics.map((topic, b) => {
                          // {bookmarkIds.map((id, b) => {
                          const title = topic?.split("-");
                          if(topic===undefined)
                          return false;

                          return (
                            <div key={topic}>
                              <div className="d-flex align-items-center justify-content-between p-2 border-bottom  my-2">
                                <h5 className="">
                                  {" "}
                                  {title[0]}
                                  {title[1] && (
                                    <span
                                      className="text-muted"
                                      style={{ fontSize: "11px" }}
                                    >
                                      {`(${title[1]})`}
                                    </span>
                                  )}
                                </h5>
                                <button
                                  type="button"
                                  class="btn btn-xs mb-0 btn-info tp-btn-light"
                                  onClick={() =>
                                    swal({
                                      title: "What do you want to revise ?",

                                      buttons: {
                                        notes: {
                                          text: "Notes",
                                          value: "notes",
                                          className: "bg-dark",
                                        },
                                        questions: {
                                          text: "Questions",
                                          value: "questions",
                                          className: "bg-info",
                                        },
                                      },
                                    }).then((v) => {
                                      if (v === "questions") {
                                        window.location = `/bookmarks?show=questions&title=${
                                          title[1] &&
                                          title[1] === "Internal Medicine"
                                            ? title[0] + "&topic=im"
                                            : topic
                                        }`;
                                      } else if (v === "notes") {
                                        // window.location = `/bookmarks?show=notes&title=${topic}`;
                                        window.location = `/bookmarks?show=notes&title=${
                                          title[1] &&
                                          title[1] === "Internal Medicine"
                                            ? title[0] + "&topic=im"
                                            : topic
                                        }`;
                                      }
                                    })
                                  }
                                >
                                  Revise
                                </button>
                              </div>
                              <div className="row no-gutters ">
                                {bookmarks[topic].map((t, i) => t!==undefined && (
                                  <div
                                    key={t.id}
                                    className="col-auto  m-3"
                                    onClick={() => {
                                      isRemove &&
                                        handleRemoveBookMarks(topic, t?.id);
                                    }}
                                  >
                                    <span
                                      className={`badge  ${
                                        isRemove &&
                                        bookMarksToDelete[topic]?.includes(
                                          t?.id
                                        )
                                          ? "badge-outline-danger"
                                          : "badge-outline-info"
                                      } badge-rounded d-flex align-items-center`}
                                      style={{
                                        fontWeight: "500",
                                        cursor: isRemove ? "pointer" : "none",
                                      }}
                                    >
                                      {t?.topic === "Internal Medicine"
                                        ? t?.title
                                        : t?.subTopic}
                                      {isRemove ? (
                                        bookMarksToDelete[topic]?.includes(
                                          t?.id
                                        ) ? (
                                          <i class="ml-2 las la-check-square la-lg  mr-1"></i>
                                        ) : (
                                          <i class=" ml-2 la la-stop la-lg  mr-1"></i>
                                        )
                                      ) : null}
                                    </span>
                                    {/* <Popover
                                      isOpen={
                                        isPopoverOpen && popoverId === t.id
                                      }
                                      // positions={["bottom", "left"]}
                                      content={
                                        <div className="bg-white shadow border-warning p-4 border c-pointer">
                                          Are sure you want to delete ?
                                          <div className="d-flex align-items-center justify-content-between mt-4">
                                            <div
                                              className="btn btn-outline-info btn-sm"
                                              onClick={() => {
                                                setIsPopoverOpen(
                                                  !isPopoverOpen
                                                );

                                                setPopoverId("");
                                              }}
                                            >
                                              cancel
                                            </div>
                                            <div
                                              className="btn btn-warning btn-sm"
                                              onClick={() => {
                                                deleteBookmark(topic, t?.id);
                                                setPopoverId("");
                                              }}
                                            >
                                              confirm
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <div className="mx-auto ">
                                        <div
                                          className=" shadow-sm  p-2 my-2 d-flex align-items-center justify-content-between flex-row"

                                          // className={`${deshBoard.includes(path) ? "mm-active" : ""}`}
                                        >
                                          <p className=" mb-0">
                                            {" "}
                                            {t?.subTopic}
                                          </p>
                                          <IconButton
                                            onClick={() => {
                                              setIsPopoverOpen(!isPopoverOpen);
                                              if (!popoverId) {
                                                setPopoverId(t.id);
                                              } else {
                                                setPopoverId("");
                                              }
                                            }}
                                            // onClick={() =>
                                            //   deleteBookmark(topic, t?.id)
                                            // }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </Popover>
                                  */}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </PerfectScrollbar>
                    ) : (
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        align="center"
                      >
                        You dont have any bookmarks yet. Click the{" "}
                        <BookmarkIcon /> button in a question to save them here
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Bookmark;
