import React from "react";
import "./success.css";
import { useHistory } from "react-router-dom";

import Logo from "./Payment Success Medrevisions.svg";
const Error400 = () => {
  window.history.forward(1);
  // document.attachEvent("onkeydown", my_onkeydown_handler);
  // function my_onkeydown_handler() {
  //   // eslint-disable-next-line default-case
  //   switch (event.keyCode) {
  //     case 116: // 'F5'
  //       event.returnValue = false;
  //       event.keyCode = 0;
  //       window.status = "We have disabled F5";
  //       break;
  //   }
  // }

  return (
    //  <div className="authincation h-100 p-meddle">
    //     <div className="container h-100">
    //        {" "}
    //        <div className="row justify-content-center h-100 align-items-center">
    //           <div className="col-md-5">
    //              <div className="form-input-content text-center error-page">
    //              <div class="logo">
    //         <img src="/Payment Success Medrevisions.svg" alt="" />
    //     </div>
    //                 <h1>Payment Successful!</h1>
    //                   <h2 >
    //          Thank you for subscribing to our plan. You can access all our
    //          features now.
    //         </h2>
    //                 <div>
    //                    <Link className="btn btn-primary" to="/">
    //                       Back to Home
    //                    </Link>
    //                 </div>
    //              </div>
    //           </div>
    //        </div>
    //     </div>
    //  </div>
    <div class="thankyou-page">
      <div class="_header">
        <div class="logo">
          <img src={Logo} alt="" />
        </div>
        <h1>Payment Successful!</h1>
      </div>
      <div class="_body">
        <div style={{ width: "50%", textAlign: "center", margin: "auto" }}>
          <h2 style={{ textAlign: "center", fontWeight: "300" }}>
            Thank you for subscribing to our plan. You can access all our
            features now.
          </h2>
        </div>
      </div>
      <div class="_footer">
        <a class="btn" href="/home">
          Go to dashboard
        </a>
      </div>
    </div>
  );
};

export default Error400;
{
  /* <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<link href="/custom.css" rel="stylesheet" id="custom-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ----------> */
}
