/* eslint-disable jsx-a11y/anchor-is-valid */
import "./index.scss";

// import {
//   Button,
//   CardActions,
//   CardContent,
//   CardHeader,
//   Grid,
//   Paper,
//   Typography,
// } from "@material-ui/core";

import React from "react";

const tiers = [
  {
    title: "3 Months Plan ",
    subHeader: "3 Months",
    price: "34",
    sku: "sku_FgRz5ujGF3Pu8x",
    term: 3,
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    features: [
      "4000+ Exam Style Questions with explanations ",
      "Complete Study Notes",
      "Revision Notes",
      "Unlimited Mocks",
      "Space Repetitive Learning too",
      "",
    ],
  },
  {
    title: "6 Months Plan ",
    subHeader: "6 Months",
    price: "48",
    sku: "sku_FgRyyre0taEYfn",
    term: 6,
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    features: [
      "4000+ Exam Style Questions with explanations ",
      "Complete Study Notes",
      "Revision Notes",
      "Unlimited Mocks",
      "Space Repetitive Learning too",
      "",
    ],
  },
  {
    title: "1 Year Plan ",
    subHeader: "12 Months",

    price: "74",
    sku: "sku_FbdKpEVZVNdtaf",
    term: 12,
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    features: [
      "4000+ Exam Style Questions with explanations ",
      "Complete Study Notes",
      "Revision Notes",
      "Unlimited Mocks",
      "Space Repetitive Learning too",
      "One-time FREE entire question bank reset ",
    ],
  },
  {
    title: "3 Years Plan",
    subHeader: "36 Months",
    price: "139",
    sku: "sku_FbdJJmr9pOltFt",
    term: 36,
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    features: [
      "4000+ Exam Style Questions with explanations ",
      "Complete Study Notes",
      "Revision Notes",
      "Unlimited Mocks",
      "Space Repetitive Learning too",
      "Two-time FREE entire question bank reset ",
    ],
  },
];

const PricingGrid = ({ showDiscount, onPlanClicked }) => {
  return (
    <>
      {/* <Typography
        align="center"
        variant="h6"
        color="primary"
        className="feature-text"
      >
        {
          "✔Over 3000 Questions  ✔Extended study notes and links  ✔Includes images  ✔X-Rays and ECGs  ✔Build unlimited timed mock exams"
        }
      </Typography> */}
      {/* <Button
        className="sample-button"
        fullWidth
        variant="contained"
        color="secondary"
        href={`/demo/plab`}
      >
        See sample questions
      </Button> */}
      <section class="pricing-table">
        <div class="container">
          <div class="block-heading">
            <h2>Pricing</h2>
            <div class="d-flex ">
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              {/* <button
                className="btn btn-warning "

                // href={`/demo/plab`}
              >
                Take a Demo For Free
              </button> */}
            </div>
          </div>
          <div class="row justify-content-md-center">
            {tiers.map((tier, t) => (
              <div class="col-md-5 col-lg-4">
                <div class="item rounded" style={{ height: "592px" }}>
                  {showDiscount && (
                    <div class="ribbon-checkout">10% Discount</div>
                  )}
                  <div class="heading">
                    <h3>{tier.title}</h3>
                  </div>
                  {/* <p>
                    {tier.subHeader}
                  </p> */}
                  <div class="features">
                    <ul>
                      {/* <span class="feature">Full Support</span> :{" "} */}
                      {tier.features.map((v) => (
                        <li class="value">{v}</li>
                      ))}
                    </ul>
                    {/* <h4>
                      <span class="feature">Duration</span> :{" "}
                      <span class="value">{tier.term} Months</span>
                    </h4> */}
                  </div>
                  <div className="price-footer">
                    <div class="price">
                      <h4> ${tier.price}</h4>
                    </div>
                    <a
                      // href={`/auth?sku=sku_FVxMLY1RDzNgGw`}
                      href={`/auth?sku=${tier.sku}`}
                      
                      // href={ `/auth?sku=${tier.sku}`}
                      onClick={() => {
                        // onPlanClicked && onPlanClicked('sku_FVxMLY1RDzNgGw');
                        onPlanClicked && onPlanClicked(tier.sku);
                      }}
                      class="btn btn-block btn-info text-white"
                      type="submit"
                    >
                      BUY NOW
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Grid container spacing={3} justify="center">
        {tiers.map((tier, t) => (
          <Grid item key={t}>
            <Paper className="price-card">
              <CardHeader
                title={tier.title}
                subheader={tier.subHeader}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
              />
              <CardContent>
                {showDiscount && (
                  <Typography className="discount-text">
                    <strong>10%</strong> Discount applied at checkout!
                  </Typography>
                )}
                <Typography
                  component="h3"
                  variant="h3"
                  color="textPrimary"
                  align="center"
                >
                  ${tier.price}
                </Typography>
                <Typography
                  align="center"
                  component="p"
                  variant="caption"
                  color="textSecondary"
                >
                  For all {tier.term} Months
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  className="select-plan-button"
                  variant="contained"
                  color="primary"
                  fullWidth
                  href={onPlanClicked ? null : `/auth?sku=${tier.sku}`}
                  onClick={() => {
                    onPlanClicked && onPlanClicked(tier.sku);
                  }}
                >
                  Select Plan
                </Button>
              </CardActions>
            </Paper>
          </Grid>
        ))}
      </Grid>
     */}
    </>
  );
};
export default PricingGrid;
