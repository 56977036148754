import React, { useState, useEffect, useContext, Fragment } from "react";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Table, Badge, Alert } from "react-bootstrap";
import { firestore, firebase } from "../../../firebase";
import AuthContext from "../../../context";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import Checkout from "../../pages/Auth/Checkout";
import MetisMenu from "metismenujs";
import paidIcon from "../../../paid.svg";
import loadingStethoscope from "../../56363-stethoscope-doctor.json";
import Lottie from "react-lottie";

class MM extends React.Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const MockExams = (props) => {
    const { userType } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [mockExams, setMockExams] = useState(1);
    const [mockLevel, setMockLevel] = useState(1);
    const [sku, setSku] = useState("");

    const stripeAPIKEY = "pk_live_eipbFT2Cpqee5C2yYgNpIKKL00oNt4500B";
    const [stripe, setStripe] = useState(false);
    const [plans, setPlans] = useState(false);
    const [submissions, setSubmissions] = useState({});
    const [mockPass, setMockPass] = useState(0);

    const showDiscount = false;
    const nowDate = new Date();

    useEffect(() => {
        getMockPassMark();
        if (userType !== "") {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    setUser(user);
                    firestore
                        .collection("mockSubscriptions")
                        .doc(user.uid)
                        .get()
                        .then((mockSnap) => {
                            if (mockSnap.exists) {
                                const sub = mockSnap.data();

                                if (sub.mockLevel) {
                                    setMockLevel(sub.mockLevel);
                                } else {
                                    setMockLevel(1);
                                }
                            } else {
                                initMockSub(user);
                                setMockLevel(1);
                            }
                        });

                    firestore
                        .collection("submissions")
                        .doc(user.uid)
                        .get()
                        .then((data) => {
                            if (data.exists) {
                                setSubmissions(data.data());
                            } else {
                                setSubmissions({});
                            }
                            getMockExams(data.data());
                        });

                }
            });
        }

        /* if (window?.Stripe) {
            setStripe(window.Stripe(stripeAPIKEY));
        } else {
            document.querySelector("#stripe-js").addEventListener("load", () => {
                setStripe(window.Stripe(stripeAPIKEY));
            });
        } */
    }, []);

    const getMockPassMark = () => {
        firestore
            .collection("admin")
            .doc("settings")
            .get()
            .then((data) => {
                setMockPass(data.data().mockPassMark);
            });
    }

    const getMockExams = async (sub) => {
        firestore
            .collection("exams")
            .get()
            .then((examSnap) => {
                let mockExams = [];
                examSnap.docs.forEach(doc => {
                    if (doc.data().name?.toLowerCase().includes('mock') && doc.data().sale == true) {
                        const data = doc.data();
                        const id = doc.id;
                        var correct = 0;
                        var wrong = 0;
                        var sumbitted = 0;
                        Object.values(sub['mock' + data.index]).map((e, i) => {
                            if (e.correct == true) {
                                correct++;
                            }
                            if (e.correct == false) {
                                wrong++;
                            }
                            sumbitted++;
                        });
                        data['correct'] = correct;
                        data['wrong'] = wrong;
                        data['sumbitted'] = sumbitted;
                        mockExams.push({ ...data, id });
                    }
                });
                setMockExams(mockExams.sort((a, b) => a.index > b.index));
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const goToMock = async (id) => {
        /* const mockRef = firestore.collection("mockSubscriptions").doc(uid);
        const mockSnap = await mockRef.get();
        if (!mockSnap.exists) {
            setMockType("user");
        } else {
            setMockType("mock_user");
        } */

        if (mockLevel < id) {
            setPlans(true);
        } else {
            window.location.href = `/mock/${id}`;
        }
    }

    const initMockSub = async (user) => {
        //setSku(sku);
        await firestore
            .collection("mockSubscriptions")
            .doc(user.uid)
            .set({
                chargeID: "free_account",
                customerEmail: user.email,
                invoiceID: null,
                periodEnd: nowDate.setFullYear(nowDate.getFullYear() + 3),
                periodStart: nowDate.getTime(),
                sku: "sku",
                skuName: "Mock",
                mockLevel: "1",
            })
            .finally(() => {
                setMockLevel(1);
                setPlans(false);
            });
    }

    const onPlanClicked = async (sku) => {
        //setSku(sku);
        await firestore
            .collection("mockSubscriptions")
            .doc(user.uid)
            .set({
                chargeID: "free_account",
                customerEmail: user.email,
                invoiceID: null,
                periodEnd: nowDate.setFullYear(nowDate.getFullYear() + 3),
                periodStart: nowDate.getTime(),
                sku: "sku",
                skuName: "Mock",
                mockLevel: "1",
            })
            .finally(() => {
                setMockLevel(1);
                setPlans(false);
            });
    }

    const tiers = [
        {
            title: "3 Months Plan ",
            subHeader: "3 Months",
            price: "34",
            sku: "sku_FgRz5ujGF3Pu8x",
            term: 3,
            buttonText: "Sign up for free",
            buttonVariant: "outlined",
            features: [
                "4000+ Exam Style Questions with explanations ",
                "Complete Study Notes",
                "Revision Notes",
                "Unlimited Mocks",
                "Space Repetitive Learning too",
                "",
            ],
        },
        {
            title: "6 Months Plan ",
            subHeader: "6 Months",
            price: "48",
            sku: "sku_FgRyyre0taEYfn",
            term: 6,
            buttonText: "Sign up for free",
            buttonVariant: "outlined",
            features: [
                "4000+ Exam Style Questions with explanations ",
                "Complete Study Notes",
                "Revision Notes",
                "Unlimited Mocks",
                "Space Repetitive Learning too",
                "",
            ],
        },
        {
            title: "1 Year Plan ",
            subHeader: "12 Months",

            price: "74",
            sku: "sku_FbdKpEVZVNdtaf",
            term: 12,
            buttonText: "Sign up for free",
            buttonVariant: "outlined",
            features: [
                "4000+ Exam Style Questions with explanations ",
                "Complete Study Notes",
                "Revision Notes",
                "Unlimited Mocks",
                "Space Repetitive Learning too",
                "One-time FREE entire question bank reset ",
            ],
        },
    ];

    return (
        <Fragment>

            {loading ? (
                <div
                    style={{ height: "48vh" }}
                    className="d-flex align-items-center justify-content-center flex-column"
                >
                    {/* <LinearProgress variant="query" /> */}

                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loadingStethoscope,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        height={200}
                        width={200}
                    // isStopped={this.state.isStopped}
                    // isPaused={this.state.isPaused}
                    />
                    <p className="text-center mt-2">Checking back your progress...</p>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        {userType === "demo_user" || userType === "expaird_user" ? (
                            <div
                                className="d-flex align-items-center justify-content-center flex-column"
                                style={{
                                    position: "absolute",
                                    zIndex: "1",
                                    height: " 100%",
                                    width: " 100%",
                                }}
                            >
                                <img src={paidIcon} />
                                <p className="mt-4 p-4   text-center">
                                    {/* Fully featured question list is disabled in this demo; you need
                to sign up for a payment plan to access the question list
                divided by topics and to access 4000+ questions.
                You check the demo question click on check examples */}
                                    This feature is disabled in the demo. You need to sign up for a
                                    paid plan to access the 4000+ questions divided by topics. You
                                    can check the demo questions by clicking on the see sample
                                    questions button.
                                </p>

                                <a
                                    type="submit"
                                    className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
                                    href={`/auth#pricing`}
                                >
                                    {" "}
                                    Subscribe to a plan
                                </a>
                                <a
                                    type="submit"
                                    className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                                    href={`/demo/plab`}
                                >
                                    {" "}
                                    See sample questions
                                </a>
                            </div>
                        ) : null}
                        {plans ? (
                            <>
                                {
                                    sku ? (
                                        <StripeProvider stripe={stripe} >
                                            <Elements>
                                                <Checkout user={user} sku={sku} />
                                            </Elements>
                                        </StripeProvider>
                                    ) : (
                                        <section class="pricing-table">
                                            <div class="container">
                                                <div class="block-heading">
                                                    <h2>Pricing</h2>
                                                    <div class="d-flex ">
                                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                                        {/* <button
                                    className="btn btn-warning "
                    
                                    // href={`/demo/plab`}
                                  >
                                    Take a Demo For Free
                                  </button> */}
                                                    </div>
                                                </div>
                                                <div class="row justify-content-md-center">
                                                    {tiers.map((tier, t) => (
                                                        <div class="col-md-5 col-lg-4" key={t}>
                                                            <div class="item rounded" style={{ height: "592px" }}>
                                                                {showDiscount && (
                                                                    <div class="ribbon-checkout">10% Discount</div>
                                                                )}
                                                                <div class="heading">
                                                                    <h3>{tier.title}</h3>
                                                                </div>
                                                                {/* <p>
                                        {tier.subHeader}
                                      </p> */}
                                                                <div class="features">
                                                                    <ul>
                                                                        {/* <span class="feature">Full Support</span> :{" "} */}
                                                                        {tier.features.map((v, i) => (
                                                                            <li class="value" key={i}>{v}</li>
                                                                        ))}
                                                                    </ul>
                                                                    {/* <h4>
                                          <span class="feature">Duration</span> :{" "}
                                          <span class="value">{tier.term} Months</span>
                                        </h4> */}
                                                                </div>
                                                                <div className="price-footer">
                                                                    <div class="price">
                                                                        <h4> ${tier.price}</h4>
                                                                    </div>
                                                                    <a
                                                                        onClick={() => {
                                                                            onPlanClicked(tier.sku);
                                                                        }}
                                                                        class="btn btn-block btn-info text-white"
                                                                        type="submit"
                                                                    >
                                                                        BUY NOW
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </section>
                                    )
                                }
                            </>
                        ) : (
                            <div
                                className={`card ${userType === "demo_user" || userType === "expaird_user"
                                    ? "blur"
                                    : ""
                                    }`}
                            >
                                <div className="card-body">
                                    <li className="list-group-item justify-content-between" style={{padding:15,marginBottom:30}}>
                                        <span className="mb-0"><strong>Mock Exam:</strong> Immerse yourself in a curated selection of questions meticulously designed to replicate real exam scenarios. Our carefully crafted questions ensure a comprehensive and thorough preparation, allowing you to simulate real-world applications. Elevate your learning experience and boost your confidence for the upcoming exam with our thoughtfully tailored Mock Exam.</span>
                                    </li>
                                    {mockExams.map((e, i) =>
                                        <MM className="metismenu " id="menu">
                                            <li
                                                className="  shadow-sm p-2 mb-3 rounded "
                                                // style={{ backgroundColor: "#fff0f0" }}
                                                style={{ position: "relative", zIndex: 1, background: e.index <= mockLevel ? "#ffc10733" : "#969ba033", cursor: e.index <= mockLevel ? "pointer" : "" }}
                                                // #e7faec
                                                //     colors: ["#00e396", "#ff4560", "#feb019"],
                                                onClick={() => goToMock(e.index)}
                                            >
                                                <div
                                                    className="  row "
                                                    // className="  d-flex align-items-center justify-content-between  flex-wrap"
                                                    aria-expanded="false"
                                                    key={1}
                                                    // to={topic.expand ? undefined : `/questions/${topic.id}`}
                                                    onClick={() => { }}
                                                >
                                                    <div className="col">
                                                        <div className="d-flex align-items-start justify-content-center flex-column ">
                                                            <h4 className="text-white-40">{e.name}</h4>

                                                            <div>
                                                                <h6 className="text-white-40">
                                                                    {e.index <= mockLevel ? 'Purchased' : 'Purchase to access'}
                                                                </h6>
                                                                {` `}
                                                                <Badge as="span" variant="info badge-rounded ">
                                                                    {e.questions ? Object.keys(e.questions)?.length : 0} Questions
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="d-flex align-items-end justify-content-between flex-column ">
                                                            <p className="text-white-40 last-atmpt">
                                                                {e.correct >= mockPass ? 'Passes' : 'Failed'}
                                                            </p>
                                                            <p className="text-white-40 last-atmpt">
                                                                Attempted {e.sumbitted} of {e.questions ? Object.keys(e.questions)?.length : 0}
                                                            </p>
                                                            <KeyboardArrowRightIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </MM>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default MockExams;