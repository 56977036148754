import React, { useState, useEffect } from "react";

/// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import loader from "../loading.svg";

import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Success from "./pages/Success";

/// Dashboard
import HomeNew from "./components/Dashboard/Home";
import Bookmark from "./components/Dashboard/Bookmark";
import QuestionList from "./components/Dashboard/QuestionList";
import Library from "./components/Dashboard/Library";
import Revision from "./components/Dashboard/Revision";
import Exams from "./components/Dashboard/Exams";
import Referral from "./components/Dashboard/Referral";
import Auth from "./pages/Auth";

import Questions from "./pages/Questions";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";
import AuthContext from "../context";
import { firebase, firestore } from "../firebase";
import MockExams from "./components/Dashboard/MockExams";
import Tags from "./components/Dashboard/Tags";
const Markup = () => {
  let path = window.location.pathname;

  path = path.split("/");
  path = path[path.length - 1];

  let pagePath =
    path.split("-").includes("page") ||
    path.split("-").includes("auth") ||
    path.split("-").includes("success");

  const [activeEvent, setActiveEvent] = useState(!path);
  const [userType, setUserType] = useState("");
  const [chekingUserType, setCheking] = useState(false);
  const routes = [
    /// Dashboard
    { url: "/", component: HomeNew },

    { url: "referral", component: Referral },
    { url: "questions", component: QuestionList },
    { url: "bookmark", component: Bookmark },
    { url: "smart-revision", component: Revision },
    { url: "time-exam", component: Exams },
    { url: "mock-exam", component: MockExams },
    { url: "auth", component: Auth },
    { url: "terms", component: Terms },
    { url: "contact", component: Contact },
    { url: "privacy", component: Privacy },
    { url: "dashboard", component: HomeNew },
    { url: "home", component: HomeNew },
    { url: "tags", component: Tags },

    { url: "questions/:topicOrExamId?/:subTopicID?", component: Questions },
    {
      url: "questions/:topicOrExamId?/:subTopicID?/:title?",
      component: Questions,
    },
    { url: "library", component: Library },
    { url: "library/:topicOrExamId?/:subTopicID?", component: Questions },
    {
      url: "library/:topicOrExamId?/:subTopicID?/:title?",
      component: Questions,
    },
    { url: "exam/:topicOrExamId", component: Questions },
    { url: "mock/:mockId", component: Questions },
    { url: "revision", component: Questions },
    { url: "bookmarks", component: Questions },
    { url: "demo/:type", component: Questions },
    { url: "tag", component: Questions },

    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "success", component: Success },
  ];
  const checkUser = async (user) => {
    setCheking(true);
    const usersRef = firestore.collection("users").doc(user.uid);
    const subsRef = firestore.collection("subscriptions").doc(user.uid);
    const userSnap = await usersRef.get();

    if (!userSnap.exists) {
      setUserType("demo_user");

    } else {
      const subSnap = await subsRef.get();

      if (!subSnap.exists) {
        setUserType("demo_user");

      } else {
        const sub = subSnap.data();
        if (sub.periodEnd < new Date().getTime()) {
          // setSubExpired(new Date(sub.periodEnd));
          setUserType("expaird_user");
        } else {
          setUserType("subscribed_user");
        }
      }
    }
    setCheking(false);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && path !== "auth") {
        checkUser(user);
      } else {
        if (path !== "auth") {
          window.location = "/auth";
        }
      }
    });
  }, []);

  return (
    <Router basename="/">
      {/* <AuthContext.Provider value={{ userType }}>
        <Switch>
          {routes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`/${data.url}`}
              component={data.component}
            />
          ))}
        </Switch>
      </AuthContext.Provider> */}
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            {chekingUserType ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
              >
                <img src={loader} />
              </div>
            ) : (
              <AuthContext.Provider value={{ userType }}>
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </AuthContext.Provider>
            )}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Markup;
