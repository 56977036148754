import React from "react";
import {
  Col,
  Badge,
  Button,
  Row,
  Accordion,
  Card,
  Tabs,
  Tab,
  Nav,
  Pagination,
  Container,
} from "react-bootstrap";
import { Link } from "@material-ui/core";
export default function RenderHelpfullLinks({helpfulLinks}) {
  return (
    <Row>
      <Col>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title card-intro-title mb-4">Helpful Links</h4>

            <>
              {helpfulLinks.map((item, k) => (
                <span key={k}>
                  {item.link &&
                    (item.link.match(/^http/) ? (
                      <Link href={item.link} target="_blank">
                        {" "}
                        {item.reason}
                      </Link>
                    ) : (
                      <div className="iframe-container">
                        <span
                          className="question-text"
                          dangerouslySetInnerHTML={{
                            __html: item.link,
                          }}
                        />
                      </div>
                    ))}
                  <br />
                </span>
              ))}
            </>
          </div>
        </div>
     
      </Col>
    </Row>
  );
}
