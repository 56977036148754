import React from "react";
import TinyView from "../../../components/Editor/tiny";
import { Badge, Button, Row, Col } from "react-bootstrap";

import AddIcon from "@material-ui/icons/Add";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReportIcon from "@material-ui/icons/Report";
import logo from "../../../../images/m-logo-text.png";
import {
  CircularProgress,
  Hidden,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Lottie from "react-lottie";
import loadingStethoscope from "../../../56363-stethoscope-doctor.json";
import ReactPaginate from "react-paginate";
import "react-slidedown/lib/slidedown.css";

export default function RenderQuestions({
  question,
  answers,
  expiredExam,
  answerSubmitted,
  match,
  toggleAnswerDescription,
  setSelectedAnswer,
  selectedAnswer,
  submittedAnswer,
  hideDesriptions,
  correctAnswer,
  showPercents,
  submissionPercent,
  showDescription,
  selectedAnswerIndex,
  renderAll,
  answerDescriptions,
  classes,
  currentQ,
  setReportmodal,
  bookmarking,
  bookmarkQuestion,
  bookmarks,
  id,
  saveToSmartRevision,
  smartRevisionSet,
  preQuestion,
  nextQuestion,
  qIds,
  submitAnswer,
  submittingAnswer,
  loading,
  onClick,
  submissions,
  currentPageData,
  handlePageClick,
  pageCount,
  exam,
  endExam,
  examMin,
  examSeconds,
  pastPaperQuestions,
  pauseExam,
  pastPaperQsOnly,
  togglePastpaperQs,
  studyessentialQsOnly,
  togglStudyEssentialQs,
  studyessentialQuestions,
  // testClick
  toggleAnswerdUnAnswerdQs,
  qsType,
  setQsType,
  allQuestions,
  isNotAnswered,
  isNotUnAnswered,
  isNotPastQs,
  isNotSeQs,
  authUser,
  prevSelectedAnswer,
  endedMock,
}) {
  const nextIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-right text-info",
  });
  const prevIcon = React.createElement("i", {
    className: "mdi mdi-chevron-double-left text-info",
  });
  const pastQs = Object.keys(pastPaperQuestions || {});
  const seQs = Object.keys(studyessentialQuestions || {});
  // const allQs = Object.keys(allQuestions || {});
  // const submitedQs = Object.keys(submissions || {}).filter(
  //   (s) => s !== "lastAttempted"
  // );
  // const answerdQs = allQs.filter((q) => submitedQs.includes(q) && q);

  // const unAnswerdPastQs = pastQs.filter(
  //   (q) => !submitedQs.includes(q) && q
  // );

  return (
    <>
      <Row>
        <Col
          sm={12}
          // md={8}
          // lg={8}
          md={{ span: 10, offset: 1 }}
          lg={{ span: 10, offset: 1 }}
        >
          <div className="card">
            <div className="card-body">
              {!loading ? (
                isNotAnswered ? (
                  <p className="text-center ">
                    No answered Question to Display
                  </p>
                ) : isNotUnAnswered ? (
                  <p className="text-center ">
                    No unanswered Question to Display
                  </p>
                ) : isNotPastQs ? (
                  <p className="text-center ">
                    No Pastpaper Question to Display
                  </p>
                ) : isNotSeQs ? (
                  <p className="text-center ">
                    No Study Essential Question to Display
                  </p>
                ) : (
                  <>
                    <div className="row mb-2 border-bottom pb-2">
                      <div className="mb-2 col-md-6 col-sm-12 col-lg-6 d-flex align-items-center justify-content-between ">
                        <h4 className="  mb-0">Question</h4>
                        {/* <button
                          className="   d-flex align-items-center justify-content-center btn-xxs  btn btn-outline-success btn-rounded"
                          onClick={() => onClick("chatbox")}
                        >
                          <i class="las la-exclamation la-lg mr-1"></i>
                          References
                        </button> */}
                      </div>

                      <div className="col-md-6 col-sm-12 col-lg-6 d-flex align-items-center justify-content-lg-end   ">
                        {/* <button
                          className=" mr-2  d-flex align-items-center justify-content-center btn-xxs  btn btn-outline-success btn-rounded"
                          onClick={() => onClick("chatbox")}
                        >
                          <i class="las la-exclamation la- mr-1"></i>
                          References
                        </button> */}
                        <div className="question-header mt-sm-1 w-100">
                          <button
                            type="button"
                            onClick={preQuestion}
                            // className="btn btn-info tp-btn-light btn-xxs d-flex align-items-center justify-content-center btn-rounded "
                            className="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                          >
                            <i class="las la-angle-left la-lg"></i>
                          </button>

                          <div className="spacer" />
                          <h5 className="m-0 mx-3">
                            {currentQ + 1}/{qIds.length}
                          </h5>
                          <div className="spacer" />

                          <button
                            type="button"
                            onClick={nextQuestion}
                            // className="btn btn-info tp-btn-light btn-xxs d-flex align-items-center justify-content-center btn-rounded "
                            className="btn btn-outline-dark btn-rounded btn-xxs d-flex align-items-center justify-content-center"
                          >
                            <i class="las la-angle-right la-lg"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {question && (
                          <div>
                            <span
                              className="question-text"
                              dangerouslySetInnerHTML={{
                                __html: question,
                              }}
                            />
                          </div>
                        )}

                        {!question && <h6>This question has been removed</h6>}
                      </div>
                    </div>
                    {question && (
                      <div className="">
                        <h5>Answers</h5>
                        <span
                          style={{
                            position: "absolute",
                            right: "10%",
                            top: "30%",
                            textAalign: "center",
                            // zIndex: "999",
                            color: "#eee",
                            display: "grid",
                          }}
                        >
                          <img
                            src={logo}
                            style={{ opacity: "0.2", maxWidth: "100px" }}
                          />
                          {authUser.email}
                        </span>

                        {Object.keys(answers || {})
                          .sort()
                          .map((a, i) => {
                            let selClass = "";
                            if (
                              match.path.match(/exam/) &&
                              !expiredExam &&
                              selectedAnswer != submittedAnswer
                            ) {
                              if (selectedAnswer === a) {
                                selClass = "selected_answer";
                              } else if (submittedAnswer === a) {
                                selClass = "prev_selected_answer";
                              }
                            }
                            if (
                              match.path.match(/mock/) &&
                              !endedMock &&
                              selectedAnswer != submittedAnswer
                            ) {
                              if (selectedAnswer === a) {
                                selClass = "selected_answer";
                              } else if (submittedAnswer === a) {
                                selClass = "prev_selected_answer";
                              }
                            } else if (selectedAnswer === a) {
                              selClass = "selected_answer";
                            } else {
                              selClass = "";
                            }

                            return (
                              <div
                                key={i}
                                onClick={() => {
                                  if (
                                    (match.path.match(/mock/) && endedMock) ||
                                    (match.path.match(/exam/) && expiredExam) ||
                                    (!match.path.match(/exam/) &&
                                      !match.path.match(/mock/) &&
                                      answerSubmitted) ||
                                    expiredExam
                                  ) {
                                    // alert("disabled");
                                    toggleAnswerDescription(i);
                                  } else {
                                    setSelectedAnswer(a);
                                  }
                                }}
                                className="row mt-4"
                              >
                                <div className="col-lg-12 ">
                                  <div className={`pl-2 py-2 ${selClass}`}>
                                    <div className="text-muted c-pointer d-flex align-items-center justify-content-between ">
                                      <div>
                                        <Badge
                                          as="span"
                                          // ="outline-info"
                                          variant={`${
                                            selectedAnswer === a
                                              ? "info"
                                              : "outline-info"
                                          }`}
                                        >
                                          {i + 1}
                                        </Badge>{" "}
                                        <span className="ml-2 question_answer mr-2">
                                          {answers[a]}
                                        </span>
                                        {answerSubmitted &&
                                          !hideDesriptions && (
                                            <>
                                              {showPercents && (
                                                <Badge
                                                  as="span"
                                                  variant={`outline-info badge-rounded badge-xs ml-2`}
                                                >
                                                  {submissionPercent[a] || 0}%
                                                </Badge>
                                              )}
                                              {(selectedAnswer === a ||
                                                correctAnswer === a) && (
                                                <Badge
                                                  as="span"
                                                  variant={`${
                                                    correctAnswer === a
                                                      ? "outline-success"
                                                      : "outline-danger"
                                                  } badge-rounded badge-xs ml-2`}
                                                >
                                                  {correctAnswer === a
                                                    ? "Correct!"
                                                    : "Wrong"}
                                                </Badge>
                                              )}
                                            </>
                                          )}
                                      </div>
                                      <div>
                                        {answerSubmitted &&
                                          !hideDesriptions &&
                                          answerDescriptions[a] &&
                                          ((showDescription &&
                                            selectedAnswerIndex === i) ||
                                          (showDescription && renderAll) ? (
                                            <i className=" la la-chevron-up la-lg"></i>
                                          ) : (
                                            <i className=" la la-chevron-down la-lg"></i>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                  {/* (showDescription && renderAll) */}
                                  <div
                                    className={` ${
                                      (showDescription &&
                                        selectedAnswerIndex === i) ||
                                      (showDescription && renderAll)
                                        ? "im-show-div"
                                        : "im-hide-div"
                                    }`}
                                    // className={"dropdown-slidedown"}
                                  >
                                    {answerSubmitted &&
                                      !hideDesriptions &&
                                      answerDescriptions[a] && (
                                        <>
                                          <div
                                            className={
                                              selectedAnswer === a ||
                                              correctAnswer === a
                                                ? correctAnswer === a
                                                  ? classes.correct
                                                  : classes.wrong
                                                : classes.info
                                            }
                                          >
                                            {/*<TinyView
                                            value={answerDescriptions[a]}
                                          />*/}
                                            <div
                                              className="notes-questions"
                                              style={{ padding: "10px" }}
                                              dangerouslySetInnerHTML={{
                                                __html: answerDescriptions[
                                                  a
                                                ].replace(
                                                  /font-family:\s*helvetica,\s*arial,\s*sans-serif;/gi,
                                                  "font-family: 'Sofia Sans', system-ui;"
                                                ),
                                              }}
                                            />
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    <div className="row mt-4 justify-content-between">
                      <button
                        className="btn btn-info  btn-rounded btn-sm"
                        onClick={() => onClick("chatbox")}
                      >
                        References
                      </button>
                      <div className="spacer" />

                      {(match.path.match(/exam/) && expiredExam) ||
                      (!match.path.match(/exam/) && answerSubmitted) ||
                      (match.path.match(/mock/) && endedMock) ? (
                        <>
                          <button
                            className="btn btn-outline-info  btn-rounded btn-sm d-flex align-items-center justify-content-center"
                            onClick={nextQuestion}
                          >
                            Next Question
                            <i class="las la-angle-right la-lg ml-1"></i>
                          </button>
                        </>
                      ) : (
                        <button
                          // className=""
                          className="btn btn-outline-info  btn-rounded btn-sm"
                          onClick={submitAnswer}
                          disabled={submittingAnswer || expiredExam}
                        >
                          {submittingAnswer ? (
                            <i
                              class="fa fa-circle-o-notch fa-spin fa-1x fa-fw "
                              style={{ color: "#2b98d6" }}
                            ></i>
                          ) : (match.path.match(/exam/) ||
                              match.path.match(/mock/)) &&
                            currentQ === qIds.length - 1 ? (
                            "Submit and end Exam"
                          ) : (
                            "Submit Answer"
                          )}
                        </button>
                      )}
                    </div>
                  </>
                )
              ) : (
                <>
                  <div
                    // style={{ height: "48vh" }}
                    className="d-flex align-items-center justify-content-center flex-column"
                  >
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingStethoscope,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      height={200}
                      width={200}
                    />
                    <p className="text-center mt-2"> Collecting Questions...</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          sm={12}
          // md={8}
          // lg={8}
          md={{ span: 10, offset: 1 }}
          lg={{ span: 10, offset: 1 }}
        >
          <div className="card">
            <div className="card-body">
              {!loading ? (
                <>
                  <div className="d-flex align-content-around flex-wrap p-2 mb-4 border justify-content-between align-items-center rounded-lg">
                    <div class="d-flex align-items-center  flex-row flex-wrap ">
                      <h5
                        onClick={() => {
                          setQsType("all");
                          toggleAnswerdUnAnswerdQs(
                            "all",
                            pastPaperQsOnly,
                            studyessentialQsOnly
                          );
                        }}
                        class={`d-flex align-items-center  m-0 mr-4 question_type ${
                          qsType === "all" ? "text-success" : "text-dark"
                        }`}
                      >
                        <i
                          class={`la  la-lg color-success mr-1 ${
                            qsType === "all"
                              ? "la-check-circle"
                              : "la-circle-notch"
                          }`}
                        ></i>
                        All
                      </h5>

                      <h5
                        onClick={() => {
                          setQsType("answerd");
                          toggleAnswerdUnAnswerdQs(
                            "answerd",
                            pastPaperQsOnly,
                            studyessentialQsOnly
                          );
                        }}
                        class={`d-flex align-items-center  m-0 mr-4 question_type ${
                          qsType === "answerd" ? "text-success" : "text-dark"
                        }`}
                      >
                        <i
                          class={`la  la-lg color-success mr-1 ${
                            qsType === "answerd"
                              ? "la-check-circle"
                              : "la-circle-notch"
                          }`}
                        ></i>
                        Answered
                      </h5>

                      <h5
                        onClick={() => {
                          setQsType("un-answerd");
                          toggleAnswerdUnAnswerdQs(
                            "un-answerd",
                            pastPaperQsOnly,
                            studyessentialQsOnly
                          );
                        }}
                        class={`d-flex align-items-center  m-0 mr-4 question_type ${
                          qsType === "un-answerd" ? "text-success" : "text-dark"
                        }`}
                      >
                        <i
                          class={`la  la-lg color-success mr-1 ${
                            qsType === "un-answerd"
                              ? "la-check-circle"
                              : "la-circle-notch"
                          }`}
                        ></i>
                        Unanswered
                      </h5>
                      {pastQs.length > 0 && (
                        <>
                          {/* <i class="las la-stop"></i> */}
                          <h5
                            onClick={() =>
                              toggleAnswerdUnAnswerdQs(
                                qsType,
                                !pastPaperQsOnly,
                                studyessentialQsOnly
                              )
                            }
                            class={`d-flex align-items-center  m-0 py-2 question_answer ${
                              pastPaperQsOnly ? "text-info" : "text-dark"
                            }`}
                          >
                            {pastPaperQsOnly ? (
                              <i class="las la-check-square la-lg  mr-1"></i>
                            ) : (
                              <i class="la la-stop la-lg  mr-1"></i>
                            )}
                            Attempt Recalls/High-yield only
                          </h5>
                          {/* <FormControlLabel
                      control={
                        <Switch
                          checked={pastPaperQsOnly}
                          onChange={(e) => togglePastpaperQs(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Attempt Recalls/High-yield questions only"
                    /> */}
                        </>
                      )}
                      {seQs.length > 0 && (
                        <>
                          {/* <i class="las la-stop"></i> */}
                          <h5
                            onClick={() =>
                              toggleAnswerdUnAnswerdQs(
                                qsType,
                                pastPaperQsOnly,
                                !studyessentialQsOnly
                              )
                            }
                            class={`d-flex align-items-center  m-0 py-2 question_answer ${
                              studyessentialQsOnly ? "text-info" : "text-dark"
                            }`}
                          >
                            {studyessentialQsOnly ? (
                              <i class="las la-check-square la-lg  mr-1"></i>
                            ) : (
                              <i class="la la-stop la-lg  mr-1"></i>
                            )}
                            Study Essentials questions only
                          </h5>
                        </>
                      )}
                    </div>
                  </div>

                  {isNotAnswered ? (
                    ""
                  ) : isNotUnAnswered ? (
                    ""
                  ) : isNotPastQs ? (
                    ""
                  ) : isNotSeQs ? (
                    ""
                  ) : (
                    <>
                      <div className="row no-gutters justify-content-center">
                        {submissions && currentPageData}
                      </div>
                      <nav aria-label="Page navigation" className="mt-4">
                        <ReactPaginate
                          previousLabel={prevIcon}
                          nextLabel={nextIcon}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          containerClassName={
                            "pagination pagination-sm justify-content-center pagination-gutter"
                          }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousLinkClassName={"page-item page-link"}
                          nextLinkClassName={"page-item page-link"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </>
                  )}
                </>
              ) : (
                <p className="text-center mt-4">
                  <i
                    class="fa fa-circle-o-notch fa-spin fa-2x fa-fw "
                    style={{ color: "#28435861" }}
                  ></i>
                  <span class="sr-only">Loading...</span>
                </p>
              )}

              {/* <Nav>{pag("sm", true, "", true, true)}</Nav> */}
              {/* 
                <Paper className="question-content question-list">
                  <Typography variant="subtitle2">Question List</Typography>

                  <List dense>
                    {submissions &&
                      qIds.map((id, i) => (
                        <ListItem
                          key={i}
                          className={currentQ === i ? "current-item" : ""}
                          button
                          onClick={() => {
                            setCurrentQuestion(i);
                          }}
                        >
                          <ListItemAvatar>
                            <span>{i + 1}</span>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <>
                                {submissions[id] ? (
                                  hideDesriptions ? (
                                    "Answered"
                                  ) : (
                                    <Chip
                                      size="small"
                                      avatar={
                                        <Avatar>
                                          {submissions[id].correct ? (
                                            <CheckIcon />
                                          ) : (
                                            <ClearIcon />
                                          )}
                                        </Avatar>
                                      }
                                      label={
                                        submissions[id].correct
                                          ? "Correct"
                                          : "Wrong"
                                      }
                                      className={`${
                                        submissions[id].correct
                                          ? "correct"
                                          : "wrong"
                                      } chip`}
                                    />
                                  )
                                ) : (
                                  <span>-- --</span>
                                )}
                              </>
                            }
                          />
                          <ListItemSecondaryAction>
                            {pastPaperQuestions[id] && (
                              <Tooltip title="This is a high yield or a recall question.">
                                <StarsIcon />
                              </Tooltip>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Paper> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
