export const getRevisionQuestions = (revisionQuestions) => {
  // 1 day, 7 days, 21 days, 2 months
  const inQueue = [],
    completed = [],
    one = [],
    seven = [],
    sixTeen = [],
    thirtyFive = [];

  const daysec = 60 * 60 * 24;
  const sevenDay = daysec * 7;
  const sixTeenDay = daysec * 16;
  const thirtyFiveDay = daysec * 35;

  Object.keys(revisionQuestions || {}).forEach((questionKey) => {
    const question = revisionQuestions[questionKey];
    const conut = Object.keys(question.count || {}).length;
    const today = Date.now() / 1000;
    // console.log("Qquestions", question);
    // console.log("lquestion.updatedAt::", question.updatedAt);
    // console.log("conut::", conut);
    const delta = today - question.updatedAt.seconds;
    console.log("delta::", delta);
    console.log("daysec::", daysec);

    if (delta < daysec) {
      console.log("lessthen one day::", delta < daysec);
      inQueue.push(questionKey);
    } else if (conut === 0) {
      console.log("conut 0::", conut === 0);
      console.log("delta,  daysec::", {delta,  daysec});

      if (delta >= daysec) {
        one.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 1) {
      console.log("conut 1::", conut === 1);
      console.log("delta,  daysec::", {delta,  daysec});
      if (delta >= sevenDay) {
        seven.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 2) {
      console.log("conut 2::", conut === 2);
      console.log("delta,  daysec::", {delta,  daysec})
      if (delta >= sixTeenDay) {
        sixTeen.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else if (conut === 3) {
      console.log("conut 3::", conut === 3);
      console.log("delta,  daysec::", {delta,  daysec})
      if (delta >= thirtyFiveDay) {
        thirtyFive.push(questionKey);
      } else {
        inQueue.push(questionKey);
      }
    } else {
      completed.push(questionKey);
    }
  });
  return {
    inQueue,
    completed,
    one,
    seven,
    sixTeen,
    thirtyFive,
  };
};
