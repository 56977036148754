import React, { Fragment, useState, useEffect } from "react";

import Profile from "./Profile";
import ProgressH from "./ProgressH";
import ProgressIM from "./ProgressIM";

import "react-slidedown/lib/slidedown.css";

import { firestore, firebase } from "../../../firebase";

import slide1 from "../../../images/slide1.png";
import slide2 from "../../../images/slide2.jpg";
import slide3 from "../../../images/slide3.png";
import paidIcon from "../../../paid.svg";
/// Bootstrap
import { Row, Col, Card, Carousel, Alert } from "react-bootstrap";
import AuthContext from "../../../context";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import "./styles.css";

const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  strokeWidth: 4,
  trailStrokeWidth: 5,
  size: 80,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper d-flex align-items-center justify-content-center flex-column">
      <div
        className="time"
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
        }}
      >
        {time}
      </div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeDays = (time) => (time / daySeconds) | 0;

const carousel2 = [
  {
    img: slide1,
    text: "Frist",
    link: "https://www.medrevisions.com/about-plab-news/tour",
  },
  {
    img: slide2,
    text: "Second",
    link: "https://www.medrevisions.com/our-updates/may-2021-plab-1-exam-recalls",
  },
  {
    img: slide3,
    text: "Third",
    link: "https://www.medrevisions.com/our-updates/december-2021-guideline-update",
  },
];

const dateString = (timeStamp) => {
  if (timeStamp) {
    const date = new Date(timeStamp.seconds * 1000);
    return date.toDateString();
  }
};
const Home = () => {
  const { userType } = React.useContext(AuthContext);
  const [user, setUser] = useState({});
  const [topics, setTopics] = useState({});
  const [subTopics, setSubTopics] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [totalQuestionCount, setTotalQuestionCount] = useState([]);
  const [referal, setReferal] = useState({});
  const [isInternalMedicine, showInterMedicineChart] = useState(false);
  const [isOK, setOK] = useState(0);
  const [settings, setSettings] = useState(0);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();

        setUser({
          ...userSnap.data(),
          id: userSnap.id,
        });
        fetchAdminSettings();
        fetchTopics();
        getSubTopics();
        fetSubscriptions(user.uid);
        fetchReferals(user.uid);
        fetchBanners();
      } else {
        // User not logged in or has just logged out.
        // window.location.href = "/auth";
      }
    });
  }, []);

  const fetchBanners = () => {
    firestore
      .collection("admin")
      .doc("settings")
      .get()
      .then((snapshot) => {
        const data = snapshot.data();
        let result = data.banner.sort(function (a, b) {
          return a.sequence - b.sequence;
        });
        setBanners(result);
      })
      .catch((error) => {
        console.error("error:::>>", error);
      });
  };

  const fetSubscriptions = (uid) => {
    firestore
      .collection("subscriptions")
      .doc(uid)
      .get()
      .then((snap) => {
        const doc = snap.data();
        setSubscription(doc);
      });
  };
  const fetchTopics = () => {
    firestore
      .collection("topics")
      .where("exam", "==", "plab")
      .get()
      .then((snapShot) => {
        const topics = {};
        let questionCount = 0;
        snapShot.forEach((topic) => {
          const data = topic.data();
          questionCount += Object.keys(data.questions || {}).length;
          topics[topic.id] = { ...data, id: topic.id };
        });
        setTotalQuestionCount(questionCount);
        fetchSubmissions(topics);
      })
      .catch((e) => {
        console.error(e);
        // window.location.href = "/auth";
      });
  };
  const getSubTopics = async () => {
    const subTopicSnap = await firestore
      .collection("subTopics")
      .where("topic", "==", "internalmedicine")
      .get();
    const subTopics = {};
    subTopicSnap.forEach((subTopic) => {
      subTopics[subTopic.id] = { ...subTopic.data(), id: subTopic.id };
    });
    setSubTopics(subTopics);
  };

  const fetchAdminSettings = async (id) => {
    const refSnap = await firestore.collection("admin").doc("settings").get();
    setSettings(refSnap.data());
  };
  const fetchReferals = async (id) => {
    const refSnap = await firestore.collection("referrals").doc(id).get();
    setReferal(refSnap.data());
  };
  const fetchSubmissions = (topics) => {
    firebase.auth().onAuthStateChanged(function (user) {
      firestore
        .collection("submissions")
        .doc(user.uid)
        .get()
        .then((snapShot) => {
          const finalTopics = {};
          const submissions = snapShot.data() || {};
          Object.keys(topics || {}).forEach((topicId) => {
            finalTopics[topicId] = {
              submissionCount:
                Object.keys(submissions[topicId] || {}).length - 1,
              ...topics[topicId],
              lastAttempted: dateString(
                (submissions[topicId] || {}).lastAttempted
              ),
            };
          });
          setSubmissions(submissions);
          setTopics(finalTopics);
        });
    });
  };

  const t = new Date(user?.examDate).getTime();
  //
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = Math.floor(new Date(t).getTime() / 1000.0); // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  //

  return (
    <Fragment>
      <>
        {!userType ||
        userType === "demo_user" ||
        userType === "expaird_user" ? (
          <>
            <Row>
              <Col>
                <Alert
                  variant="info"
                  className="alert-alt alert-dismissible fade show"
                >
                  You need to need to subscribe to a plan to access all the
                  features available. You can check the limited features for
                  this demo by clicking on the respective tab. You can take the
                  tour of our features by clicking on the image below
                </Alert>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                {/* <Carousel>
                  {carousel2.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      <a href={carousel.link} target="_blank">
                        <img
                          className="d-block  rounded"
                          src={carousel.img}
                          style={{ width: "600px", margin: " 0 auto" }}
                          alt={`${carousel.text} slide`}
                        />
                      </a>
                      <Carousel.Caption className=" d-none d-md-block">
                      
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel> */}
                       <Carousel interval={3000}>
                  {banners?.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      <a href={carousel?.cta} target="_blank">
                        <img
                        style={{ width: "600px", margin: " 0 auto" }}
                        className="d-block  rounded"
                          // src={carousel.img}
                          src={carousel.url}
                          alt={`${carousel.name} slide`}
                        />
                      </a>
                      <Carousel.Caption className=" d-none d-md-block">
                     
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
            </Row>
            <Card>
              <Card.Body className="p-4">
                <div
                  className="d-flex align-items-center justify-content-center flex-column p-2"
                  // style={{ height: "50vh" }}
                >
                  <img src={paidIcon} />
                  <p className="mt-4">
                    Features are limited in this demo. To access all the
                    features, you need a sign up for a plan
                  </p>
                  <div className="d-flex align-items-center justify-content-center ">
                    <a
                      type="submit"
                      className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
                      href={`/auth#pricing`}
                    >
                      {" "}
                      Subscribe to a plan
                    </a>
                    <a
                      type="submit"
                      className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                      href={`/demo/plab`}
                    >
                      {" "}
                      See sample questions
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
        ) : (
          <>
            <Row>
              <Col className="h-25">
                <Card>
                  <Card.Body className="p-4">
                    <h2 className="card-title">My Exam Date</h2>
                    <div className="d-flex align-items-center justify-content-between flex-column">
                      <p className="mb-0">
                        {user?.examDate && (
                          <>
                            {new Date(user?.examDate).toDateString()} -
                            {new Date(user?.examDate).toLocaleTimeString()}
                          </>
                        )}
                      </p>
                      {user?.examDate && (
                        <>
                          <CountdownCircleTimer
                            {...timerProps}
                            colors={[["#7E2E84"]]}
                            duration={daysDuration}
                            initialRemainingTime={remainingTime}
                          >
                            {({ elapsedTime }) =>
                              renderTime(
                                "days",
                                getTimeDays(daysDuration - elapsedTime)
                              )
                            }
                          </CountdownCircleTimer>
                          {/* <CountdownCircleTimer
                        {...timerProps}
                        colors={[["#D14081"]]}
                        duration={daySeconds}
                        initialRemainingTime={remainingTime % daySeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > hourSeconds,
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime(
                            "hours",
                            getTimeHours(daySeconds - elapsedTime)
                          )
                        }
                      </CountdownCircleTimer>
                      <CountdownCircleTimer
                        {...timerProps}
                        colors={[["#EF798A"]]}
                        duration={hourSeconds}
                        initialRemainingTime={remainingTime % hourSeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > minuteSeconds,
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime(
                            "minutes",
                            getTimeMinutes(hourSeconds - elapsedTime)
                          )
                        }
                      </CountdownCircleTimer>
                      <CountdownCircleTimer
                        {...timerProps}
                        colors={[["#218380"]]}
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => [
                          remainingTime - totalElapsedTime > 0,
                        ]}
                      >
                        {({ elapsedTime }) =>
                          renderTime("seconds", getTimeSeconds(elapsedTime))
                        }
                      </CountdownCircleTimer> */}
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <Card>
                  <Card.Body className="p-4">
                    <Profile
                      user={user}
                      subscription={subscription}
                      referal={referal}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={8} className="h-25">
                <Card>
                  <Card.Body className="p-4">
                    <h4 className="card-intro-title"></h4>
                    <Carousel interval={3000}>
                      {banners?.map((carousel, i) => (
                        <Carousel.Item key={i}>
                          <a href={carousel?.cta} target="_blank">
                            <img
                              style={{
                                // width: "auto",
                                // height: "325px",
                                // maxHeight: "325px",
                                maxWidth: "100%",
                                height: "auto",
                              }}
                              className="d-block w-100 rounded test "
                              // src={carousel.img}
                              src={carousel.url}
                              alt={`${carousel.name} slide`}
                            />
                          </a>
                          <Carousel.Caption className=" d-none d-md-block">
                            {/* <h5>{carousel.text} slide label</h5>
                            <p>
                              Nulla vitae elit libero, a pharetra augue mollis
                              interdum.
                            </p> */}
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <div className="row">
              <div className="col-xl-8 col-lg-12 col-sm-12">
                {/* <div className="card">
              <div className="card-header border-0 pb-0">
                <h2 className="card-title">Referral</h2>
                <button
                  onClick={() => setDontShow()}
                  className="btn btn-sm btn-outline-info"
                >
                  Dont show this message
                </button>
              </div>
              <div className="card-body pb-0">
               
                {!dontShowFlag && !window.localStorage.getItem("dontShowFlag") && (
                  <>
                    <img
                      className="referral-img"
                      style={{ height: "100px" }}
                      src={ReferralImg}
                    />
                    <div>
                      <h4
                      className="text-center"
                      >
                        Referral Program
                      </h4>
                      <h6 variant="subtitle1" align="center" gutterBottom>
                        Use your unique referral link to earn{" "}
                        <strong>
                          US$5 every time someone signs up using your link
                        </strong>
                        . They will receive a 10% discount! <br />
                        And it's just good karma!
                        <br />
                        <div className="link"> Referral Link: {shareLink}</div>
                      </h6>
                      <p variant="subtitle1" align="center" gutterBottom>
                        Click on a social media below for quick share
                      </p>
                      <div className="d-flex pb-2">
                        <FacebookShareButton
                          url={shareLink}
                          className="button-link"
                          quote={shareText}
                        >
                          <IconButton>
                            <FacebookIcon />
                          </IconButton>
                        </FacebookShareButton>

                        <LinkedinShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          <IconButton>
                            <LinkedInIcon />
                          </IconButton>
                        </LinkedinShareButton>

                        <TwitterShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                          hashtags={["medrevisions"]}
                        >
                          <IconButton>
                            <TwitterIcon />
                          </IconButton>
                        </TwitterShareButton>

                        <TelegramShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          <IconButton>
                            <TelegramIcon />
                          </IconButton>
                        </TelegramShareButton>

                        <WhatsappShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          <IconButton>
                            <WhatsAppIcon />
                          </IconButton>
                        </WhatsappShareButton>

                        <RedditShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          <IconButton>
                            <RedditIcon />
                          </IconButton>
                        </RedditShareButton>

                          <LineShareButton
                            url={shareLink}
                            title={shareText}
                            className="button-link"
                          >
                            Line
                          </LineShareButton>

                        <EmailShareButton
                          url={shareLink}
                          title={shareText}
                          className="button-link"
                        >
                          <IconButton>
                            <EmailIcon />
                          </IconButton>
                        </EmailShareButton>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
        */}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h2 className="card-title">Progress</h2>
                  </div>
                  <div className="card-body">
                    <div id="chart">
                      <ProgressH
                        onClickChart={() => {
                          showInterMedicineChart(!isInternalMedicine);
                          window.scrollTo(0, document.body.scrollHeight);
                        }}
                        submissions={submissions}
                        topics={topics}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`mb-1 ${
                isInternalMedicine ? "im-show-div" : "im-hide-div"
              }`}
            >
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-sm-12">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h2 className="card-title">Internal Medicine Progress</h2>
                      <button
                        className="btn  btn-sm btn-outline-info"
                        onClick={() => {
                          showInterMedicineChart(!isInternalMedicine);
                        }}
                      >
                        Hide
                      </button>
                    </div>
                    <div className="card-body">
                      {/* <ProgressH submissions={submissions} topics={topics} /> */}
                      <div id="chart">
                        {/* <ReactApexChart
                      options={_state.options}
                      series={_state.series}
                      type="bar"
                      height={350}
                    /> */}
                        <ProgressIM
                          submissions={submissions}
                          topics={topics}
                          subTopics={subTopics}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* 
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-sm-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h2 className="card-title">Progress</h2>
              </div>
              <div className="card-body">
                <Revision user={user} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-sm-12">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h2 className="card-title">Progress</h2>
              </div>

              <div className="card-body">
                <Exams user={user} availableQuestions={totalQuestionCount} />
              </div>
            </div>
          </div>
        </div>
     */}
      </>
    </Fragment>
  );
};

export default Home;
