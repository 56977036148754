import React, { useState } from "react";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Badge, Alert } from "react-bootstrap";

import MetisMenu from "metismenujs";
import { Link, useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import loadingStethoscope from "../../56363-stethoscope-doctor.json";
import PerfectScrollbar from "react-perfect-scrollbar";

import { firestore } from "../../../firebase";
const pluralize = require("pluralize");

class MM extends React.Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
const PER_PAGE = 5;
const Questions = (props) => {
  const history = useHistory();
  // const { userType } = useContext(AuthContext);
  const [expanded, setExpanded] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const { totalQuestionCount, topics, submissions, studyessentialQsOnly } = props;
  const topicList = Object.values(topics);

  const getExpandedItems = async (topicId) => {
    if (topicId) {
      const subTopicSnap = await firestore
        .collection("subTopics")
        .where("topic", "==", topicId)
        .get();
      const subTopics = [];
      subTopicSnap.forEach((subTopic) => {
        let c = 0;
        let w = 0;

        // if (subTopicId !== "lastAttempted") {
        Object.keys(submissions["internalmedicine"] || {}).map((questionId) => {
          if (
            submissions["internalmedicine"][questionId]["subTopicID"] ===
            subTopic.id
          ) {
            if (submissions["internalmedicine"][questionId].correct) {
              c += 1;
            } else {
              w += 1;
            }
          }
        });

        subTopics.push({
          id: subTopic.id,
          correct: c,
          wrong: w,
          ...subTopic.data(),
        });
      });
      setExpandedItems({ ...expandedItems, [topicId]: subTopics });
      setExpanded({
        ...expanded,
        [topicId]: !expanded[topicId],
      });
    } else {
      return [];
    }
  };

  const [searchQuery, setSearch] = useState("");

  // colors: ["#00e3969c", "#ff456094", "#ffc10785"],
  const currentPageData = topicList
    .filter((t) => t?.name?.toLowerCase().includes(searchQuery?.toLowerCase()))
    .map((topic, t) => {
      let correct = [];
      let wrong = [];
      let totalq;
      if(studyessentialQsOnly){
        totalq = Object.keys(topic.studyessentialQuestions || {}).length;
      }else{
        totalq = Object.keys(topic.questions || {}).length;
      }
      
      submissions[topic.id] &&
        Object.keys(submissions[topic.id]).forEach((key) => {
          if (submissions[topic.id][key]?.correct === true) {
            correct.push(key);
          } else {
            wrong.push(key);
          }
        });

      const correct_percentage = Math.floor((correct.length / totalq) * 100);
      const wrong_percentage = Math.floor((wrong.length / totalq) * 100);
      const unanswerd_percentage =
        100 -
        Math.floor((correct.length / totalq) * 100) -
        Math.floor((wrong.length / totalq) * 100);

      return (
        <span key={t}>
          <MM className="metismenu " id="menu">
            <li
              className="  shadow-sm p-2 mb-3 rounded "
              // style={{ backgroundColor: "#fff0f0" }}
              style={{ position: "relative", zIndex: 1 }}
              // #e7faec
              //     colors: ["#00e396", "#ff4560", "#feb019"],
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: -1,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  width: "100%",
                  // height: "6rem",
                }}
              >
                <div className="d-flex flex-row" style={{ height: "100%" }}>
                  <div
                    style={{
                      background: "#00e39636",
                      width: `${correct_percentage}%`,
                      height: "100%",

                      // position: "absolute",
                      // right: 0,
                      // top: 0,
                      // bottom: 0,
                      // zIndex: -1,
                    }}
                  ></div>
                  <div
                    style={{
                      background: "#ff456047",
                      width: `${wrong_percentage}%`,
                      height: "100%",

                      // position: "absolute",
                      // right: 0,
                      // top: 0,
                      // bottom: 0,
                      // zIndex: -1,
                    }}
                  ></div>
                  <div
                    style={{
                      background: "#ffc10733",
                      width: `${unanswerd_percentage}%`,
                      height: "100%",
                      // position: "absolute",
                      // right: 0,
                      // top: 0,
                      // bottom: 0,
                      // zIndex: -1,
                    }}
                  ></div>
                </div>
              </div>

              {/* Math.round(5 / 50 * 100) */}
              <div
                className="  row "
                // className="  d-flex align-items-center justify-content-between  flex-wrap"
                aria-expanded="false"
                key={topic.id}
                // to={topic.expand ? undefined : `/questions/${topic.id}`}
                onClick={
                  topic.expand
                    ? () => {
                        getExpandedItems(topic.id);
                      }
                    : () => {
                        history.push(`/questions/${topic.id}`);
                      }
                }
              >
                <div className="col">
                  <div className="d-flex align-items-start justify-content-center flex-column ">
                    <h4 className="text-white-40">{topic.name}</h4>

                    <div>
                      <h6 className="text-white-40">
                        {`Attempted ${
                          submissions[topic.id]
                            ? topic.submissionCount
                            : 0
                        } of ${studyessentialQsOnly?Object.keys(topic.studyessentialQuestions || {}).length:Object.keys(topic.questions || {}).length}`}
                      </h6>
                      {` `}
                      {Object.keys(topic.pastQuestions || {}).length > 0 && (
                        <Badge as="span" variant="info badge-rounded ">
                          {pluralize(
                            "High-yield / Recalls questions",
                            Object.keys(topic.pastQuestions || {}).length,
                            true
                          )}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-end justify-content-between flex-column q-mbl">
                    <p className="text-white-40 last-atmpt">
                      {topic.lastAttempted &&
                        `Last Attempted: ${topic.lastAttempted}`}
                    </p>

                    {topic.expand ? (
                      expanded[topic.id] ? (
                        <ExpandLess color="secondary" />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </div>
                </div>
              </div>

              {topic.expand && expanded[topic.id] && (
                <ul aria-expanded="false">
                  {expanded[topic.id] && (
                    <div>
                      {expandedItems[topic.id].map((item, p) => (
                        <li key={p}>
                          <Link
                            className=" mx-4 d-flex align-items-center justify-content-between border-bottom pb-2 pt-4"
                            to={`/questions/${topic.id}/${item.id}`}
                          >
                            <div className="d-flex flex-column pt-1 p">
                              <h4 className="  text-white-40">{item.name}</h4>
                              <div className="d-flex ">
                                {(item?.correct > 0 || item?.wrong > 0) && (
                                  <>
                                    <p className="pr-1 mb-0">Attempted - </p>
                                    <p className="d-flex mb-0">
                                      
                                      <span className=" text-bold ">
                                        {item?.correct + item?.wrong}
                                      </span>
                                      {/* /
                                      <span className="text-danger text-bold ">
                                        
                                      </span> */}
                                      
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>

                            {/* <RemoveIcon /> */}

                            <KeyboardArrowRightIcon />
                          </Link>
                        </li>
                      ))}
                    </div>
                  )}
                </ul>
              )}
            </li>
          </MM>
        </span>
      );
    });

  return (
    <>
      {/* <Paper className="dashbaord-container dashboard-questionlist"> */}

      <>
        <div className="form-head d-flex mb-4 mb-md-5 align-items-center justify-content-between ">
          <div className="input-group search-area d-inline-flex">
            <div className="input-group-append">
              <span
                className="input-group-text"
                style={{ borderRadius: "20px 0px 0px 20px" }}
              >
                <i className="flaticon-381-search-2" />
              </span>
            </div>
            <input
              style={{ borderRadius: "0 20px 20px 0",zIndex:1 }}
              onChange={({ target }) => setSearch(target.value)}
              type="text"
              className="form-control"
              placeholder="Search here"
            />
          </div>
          <p className="mb-0"> Total Questions - {totalQuestionCount}</p>
        </div>
        <Alert
          // show={visible}
          variant={"info"}
          className="alert fade show mb-4"
        >
          <i className="fa fa-info-circle mr-2"></i>
          Recalls/High yield questions is a beta feature. We will be adding new
          and improved questions daily.
        </Alert>

        {topicList.length === 0 ? (
          <div
            style={{ height: "48vh" }}
            className="d-flex align-items-center justify-content-center flex-column"
          >
            {/* <LinearProgress variant="query" /> */}

            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingStethoscope,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
            <p className="text-center mt-2">Checking back your progress...</p>
          </div>
        ) : (
          <>
            <PerfectScrollbar
              style={{
                // height: visible ? "48vh" : "58vh",
                paddingRight: "1rem",
              }}
              id="DZ_W_Todo3"
              className="widget-media dz-scroll  ps ps--active-y"
            >
              {currentPageData}
            </PerfectScrollbar>
          </>
        )}
      </>
    </>
  );
};
export default Questions;
