import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © MedRevisions. All rights reserved. "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(2),
    display: "flex",
    borderTop: "1px solid #e8e8e8",
    marginTop: theme.spacing(2)
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <footer>
        <Container className={classes.footer}>
          <Copyright />
          <div className="spacer" />
          <Button href="/terms" size="small">
            Terms and conditions
          </Button>
          <Button href="/privacy" size="small">
            Privacy
          </Button>
          <Button href="/contact" size="small">
            Contact US
          </Button>
        </Container>
      </footer>
    </>
  );
}
