import React, { Fragment, useState, useEffect, useContext } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Dropdown } from "react-bootstrap";
import Questions from "./Questions";
import demoSubmision from "./demoSubmision";
import demoTopis from "./demoTopis";
import AuthContext from "../../../context";
import { Link } from "react-router-dom";
import { Row, Card, Nav, Col, Pagination } from "react-bootstrap";
import { firebase, firestore } from "../../../firebase";

import paidIcon from "../../../paid.svg";

const QuestionList = () => {
  const { userType } = useContext(AuthContext);
  const active = 1;
  let items = [];

  for (let number = 1; number <= 4; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${variant && `pagination-${variant}`
        } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-left" />
        </Link>
      </li>
      {items}
      <li className="page-item page-indicator">
        <Link className="page-link" to="#">
          <i className="la la-angle-right" />
        </Link>
      </li>
    </Pagination>
  );

  const drop = (
    <Dropdown>
      <Dropdown.Toggle variant="" className="table-dropdown i-false">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#">Edit</Dropdown.Item>

        <Dropdown.Item href="#" className="text-danger">
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const chackbox = document.querySelectorAll(".customer_shop_single input");
  const motherChackBox = document.querySelector(".customer_shop input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const chack = (i) => (
    <div className={`custom-control custom-checkbox ml-2`}>
      <input
        type="checkbox"
        className="custom-control-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="custom-control-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );
  const [topics, setTopics] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [totalQuestionCount, setTotalQuestionCount] = useState([]);
  const [studyessentialQsOnly, setStudyessentialQsOnly] = useState(false);

  const dateString = (timeStamp) => {
    if (timeStamp) {
      const date = new Date(timeStamp.seconds * 1000);
      return date.toDateString();
    }
  };
  const fetchTopics = (soq) => {
    firestore
      .collection("topics")
      .where("exam", "==", "plab")
      .get()
      .then((snapShot) => {
        const topics = {};
        let questionCount = 0;
        snapShot.forEach((topic) => {
          const data = topic.data();
          if (soq) {
            questionCount += Object.keys(data.studyessentialQuestions || {}).length;
          } else {
            questionCount += Object.keys(data.questions || {}).length;
          }

          topics[topic.id] = { ...data, id: topic.id };
        });
        setTotalQuestionCount(questionCount);
        fetchSubmissions(topics,soq);
      })
      .catch((e) => {
        console.error(e);
        // window.location.href = "/auth";
      });
  };

  const fetchSubmissions = (topics,soq) => {
    firebase.auth().onAuthStateChanged(function (user) {
      firestore
        .collection("submissions")
        .doc(user.uid)
        .get()
        .then((snapShot) => {
          const finalTopics = {};
          const submissions = snapShot.data() || {};

          Object.keys(topics || {}).forEach((topicId) => {
            if (soq) {
              var found = [];
              if(topics[topicId].studyessentialQuestions!=undefined){
                Object.keys(topics[topicId].studyessentialQuestions).forEach(element => {
                  if(submissions[topicId] && Object.keys(submissions[topicId]).find(e=>e==element)){
                    found.push(element);
                  }
                });

                finalTopics[topicId] = {
                  submissionCount:found.length,
                  ...topics[topicId],
                  lastAttempted: dateString(
                    (submissions[topicId] || {}).lastAttempted
                  ),
                };
              }else{
                finalTopics[topicId] = {};
              }
            } else {
              finalTopics[topicId] = {
                submissionCount:
                  Object.keys(submissions[topicId] || {}).length - 1,
                ...topics[topicId],
                lastAttempted: dateString(
                  (submissions[topicId] || {}).lastAttempted
                ),
              };
            }



          });
          setSubmissions(submissions);
          setTopics(finalTopics);
        });
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firestore
          .collection("users")
          .doc(user.uid)
          .get();

        setStudyessentialQsOnly(userSnap.data().studyessentialQsOnly || false);
        // setUser({
        //   ...userSnap.data(),
        //   id: userSnap.id,
        // });

        fetchTopics(userSnap.data().studyessentialQsOnly || false);
        // fetSubscriptions(user.uid);
        // fetchReferals(user.uid);
      } else {
        // User not logged in or has just logged out.
      }
    });
  }, []);

  return (
    <Fragment>
      {/* <PageTitle
        activeMenu="List"
        motherMenu="Questions"
        style={{ marginBottom: 0 }}
      /> */}
      <div className="row">
        <div className="col-lg-12">
          {userType === "demo_user" || userType === "expaird_user" ? (
            <div
              className="d-flex align-items-center justify-content-center flex-column"
              style={{
                position: "absolute",
                zIndex: "1",
                height: " 100%",
                width: " 100%",
              }}
            >
              <img src={paidIcon} />
              <p className="mt-4 p-4   text-center">
                {/* Fully featured question list is disabled in this demo; you need
                to sign up for a payment plan to access the question list
                divided by topics and to access 4000+ questions.
                You check the demo question click on check examples */}
                This feature is disabled in the demo. You need to sign up for a
                paid plan to access the 4000+ questions divided by topics. You
                can check the demo questions by clicking on the see sample
                questions button.
              </p>

              <a
                type="submit"
                className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
                href={`/auth#pricing`}
              >
                {" "}
                Subscribe to a plan
              </a>
              <a
                type="submit"
                className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                href={`/demo/plab`}
              >
                {" "}
                See sample questions
              </a>
            </div>
          ) : null}
          <div
            className={`card ${userType === "demo_user" || userType === "expaird_user"
              ? "blur"
              : ""
              }`}
          >
            <div className="card-body">
              {/* <div className="card-header d-none d-sm-block d-xl-none d-md-none">
                <h4 className="card-title"> Bookmarks</h4>
              </div> */}
              {/* 4353 */}
              <Questions
                submissions={
                  userType === "demo_user" ? demoSubmision : submissions
                }
                totalQuestionCount={
                  userType === "demo_user" ? 4353 : totalQuestionCount
                }
                topics={userType === "demo_user" ? demoTopis : topics}
                studyessentialQsOnly={studyessentialQsOnly}
              />

              {/* <Nav>{pag("", true, "info", false, false)}</Nav> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QuestionList;
