// import Checkout from "./Checkout";
// import { Elements } from "react-stripe-elements";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import Particles from "react-particles-js";
// import PricingGrid from "../../components/PricingGrid";
// import { StripeProvider } from "react-stripe-elements";
// import axios from "axios";
// import particlesProps from "../particles";
// import "firebaseui/dist/firebaseui.css";

// const stripeAPIKEY = "pk_live_eipbFT2Cpqee5C2yYgNpIKKL00oNt4500B";
// const HOST = " https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

// const Auth = (props) => {
//   const [firebaseuiLoaded, setFirebaseuiLoaded] = useState(false);
//   const [stripe, setStripe] = useState(false);
//   const [checkingStatus, setCheckingStatus] = useState(false);
//   const [authedUser, setAuthedUser] = useState(null);
//   const [subExpired, setSubExpired] = useState(null);
//   const [sku, setSku] = useState(
//     window.location.search.match(/sku=/) &&
//       window.location.search.replace(/\?sku=/, "")
//   );
//   const refToken = window.localStorage.getItem("ref");

//   // if (!authedUser) {
//   const firebaseui = require("firebaseui");
//   const uiConfig = {
//     signInOptions: [
//       firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//       // firebase.auth.TwitterAuthProvider.PROVIDER_ID
//     ],
//     callbacks: {
//       signInSuccessWithAuthResult: (authResult) => {
//         signInSuccessCallBack(authResult);
//         return false;
//       },
//       uiShown: () => {
//         setFirebaseuiLoaded(true);
//       },
//     },
//     signInFlow: "popup",
//     tosUrl: "/terms",
//     credentialHelper: firebaseui.auth.CredentialHelper.NONE,
//     privacyPolicyUrl: function () {
//       window.location.assign("/privacy");
//     },
//   };
//   const ui =
//     firebaseui.auth.AuthUI.getInstance() ||
//     new firebaseui.auth.AuthUI(firebase.auth());
//   ui.start("#firebaseui-auth-container", uiConfig);
//   // }

//   useEffect(() => {
//     firebase.auth().onAuthStateChanged((authedUser) => {
//       if (authedUser) {
//         checkUser(authedUser);
//       }
//     });
//     if (window.Stripe) {
//       setStripe(window.Stripe(stripeAPIKEY));
//     } else {
//       document.querySelector("#stripe-js").addEventListener("load", () => {
//         setStripe(window.Stripe(stripeAPIKEY));
//       });
//     }
//   }, []);

//   const signInSuccessCallBack = (authResult) => {
//     checkUser(authResult.user);
//   };

//   const checkRef = async () => {
//     if (refToken) {
//       const token = await firebase.auth().currentUser.getIdToken();
//       const response = await axios.post(
//         `${HOST}/verifyRefToken`,
//         {
//           refToken,
//         },
//         { headers: { authorization: "Bearer " + token } }
//       );
//       if (response.data.validReferal) {
//         return true;
//       } else {
//         return false;
//       }
//     }
//   };

//   const onPlanClicked = (sku) => {
//     setSku(sku);
//   };

//   const checkUser = async (user) => {
//     setCheckingStatus(true);
//     const usersRef = firestore.collection("users").doc(user.uid);
//     const subsRef = firestore.collection("subscriptions").doc(user.uid);

//     const userState = {
//       id: user.uid,
//       name: user.displayName,
//       email: user.email,
//       photoUrl: user.photoURL,
//       emailVerified: user.emailVerified,
//     };
//     const userSnap = await usersRef.get();

//     if (!userSnap.exists) {
//       const validRef = await checkRef();
//       if (validRef) {
//         userState.refFrom = refToken;
//       }
//       await usersRef.set(userState, { merge: true });
//       setCheckingStatus(false);
//       console.log("1");
//       setAuthedUser(userState);
//     } else {
//       const subSnap = await subsRef.get();
//       setCheckingStatus(false);
//       console.log("2");
//       setAuthedUser(userState);
//       if (!subSnap.exists) {
//         const validRef = await checkRef();
//         if (validRef) {
//           userState.refFrom = refToken;
//         }
//       } else {
//         const sub = subSnap.data();
//         if (sub.periodEnd < new Date().getTime()) {
//           setSubExpired(new Date(sub.periodEnd));
//         } else {
//           window.location.href = "/dashboard";
//         }
//       }
//     }
//   };

//   const showDiscount = authedUser && authedUser.refFrom;
//   particlesProps.particles.line_linked.color = "#0277bd";
//   particlesProps.particles.line_linked.opacity = 0.7;

//   return (
//     // <Grid container className="auth">
//     //   {/* <Hidden xsDown> */}
//     //   <Grid item sm={6} className="auth-left">
//     //     <Particles className="particles" params={particlesProps} />
//     //     <div className="text-wrapper">
//     //       <Typography variant="h2" gutterBottom>
//     //         {authedUser ? `Hello ${authedUser.name}!` : "Lets get started!"}
//     //       </Typography>
//     //       <Typography variant="h6">
//     //         {authedUser
//     //           ? ""
//     //           : "You can create an account or sign in using an email address or a social account"}
//     //       </Typography>
//     //       {showDiscount && (
//     //         <>
//     //           <Typography
//     //             variant="button"
//     //             className="discount-text"
//     //             gutterBottom
//     //           >
//     //             Thank you for accepting the referal offer.
//     //           </Typography>
//     //           <br />
//     //           <br />
//     //           <Typography
//     //             variant="button"
//     //             className="discount-text"
//     //             gutterBottom
//     //           >
//     //             <strong> Enjoy a 10% discount! </strong>
//     //           </Typography>
//     //         </>
//     //       )}
//     //     </div>
//     //   </Grid>
//     //   {/* </Hidden> */}

//     //   <Grid item xs={12} sm={6} className="auth-right">
//     //     <div className="auth-content">
//     //       {!firebaseuiLoaded && !sku && <LinearProgress color="secondary" />}

//     //       {authedUser &&
//     //         (checkingStatus ? (
//     //           <div>
//     //             <Typography variant="subtitle1" color="textSecondary">
//     //               Checking for subscriptions...
//     //             </Typography>
//     //             <LinearProgress color="secondary" />
//     //           </div>
//     //         ) : sku ? (
//     //           <StripeProvider stripe={stripe}>
//     //             <Elements>
//     //               <Checkout user={authedUser} sku={sku} />
//     //             </Elements>
//     //           </StripeProvider>
//     //         ) : (
//     //           <>
//     //             <Typography variant="h4" gutterBottom align="center">
//     //               {subExpired
//     //                 ? "Select a plan to renew your membership"
//     //                 : "Pick a plan to get started"}
//     //             </Typography>

//     //             <br />

//     //             {subExpired && (
//     //               <Typography className="error-message" variant="h6">
//     //                 Your subscription was expired on{" "}
//     //                 {subExpired.toLocaleDateString()}{" "}
//     //                 {subExpired.toLocaleTimeString()}. Please select a plan to
//     //                 renew your subscription.
//     //               </Typography>
//     //             )}
//     //             <br />
//     //             <PricingGrid
//     //               showDiscount={showDiscount}
//     //               onPlanClicked={onPlanClicked}
//     //             />
//     //           </>
//     //         ))}
//     //     </div>
//     //   </Grid>
//     // </Grid>
//     <>
//       <p>s...................................................................</p>
//       <div id="firebaseui-auth-container" />
//       {!authedUser ? <div id="firebaseui-auth-container" /> : null}
//     </>
//   );
// };

// export default Auth;

import React, { useState, useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase, firestore } from "../../../firebase";

import Checkout from "./Checkout";
import { Elements } from "react-stripe-elements";
import LinearProgress from "@material-ui/core/LinearProgress";
import PricingGrid from "../../components/PricingGrid";
import { StripeProvider } from "react-stripe-elements";
import axios from "axios";
import logo from "../../../images/m-logo-text.png";

const stripeAPIKEY = "pk_live_eipbFT2Cpqee5C2yYgNpIKKL00oNt4500B";
// const stripeAPIKEY = "pk_test_1VQ3S26ChnRcDylLKgCGEC14008hyn27Xy";

// const HOST = "http://localhost:5001/medicalapp-ad2fc/us-central1";
const HOST = "https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

const Login = ({ history }) => {
  const [loginData, setLoginData] = useState({});

  const [firebaseuiLoaded, setFirebaseuiLoaded] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [authedUser, setAuthedUser] = useState(null);
  const [subExpired, setSubExpired] = useState(null);
  const [sku, setSku] = useState(
    window.location.search.match(/sku=/) &&
      window.location.search.replace(/\?sku=/, "")
  );
  const refToken = window.localStorage.getItem("ref");

  // Configure FirebaseUI.
  const uiConfig = {
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        signInSuccessCallBack(authResult);
        return false;
      },
      uiShown: () => {
        setFirebaseuiLoaded(true);
      },
    },
    signInFlow: "popup",
    tosUrl: "/terms",
    // credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    privacyPolicyUrl: function () {
      window.location.assign("/privacy");
    },
  };
  const checkUser = async (user) => {
    setCheckingStatus(true);
    const usersRef = firestore.collection("users").doc(user.uid);
    const subsRef = firestore.collection("subscriptions").doc(user.uid);

    const userState = {
      id: user.uid,
      name: user.displayName,
      email: user.email,
      photoUrl: user.photoURL,
      emailVerified: user.emailVerified,
      //TODO:store  created at in user
    };
    const userSnap = await usersRef.get();

    if (!userSnap.exists) {
      const validRef = await checkRef();
      if (validRef) {
        userState.refFrom = refToken;
      }
      await usersRef.set(userState, { merge: true });
      setCheckingStatus(false);
      setAuthedUser(userState);
    } else {
      const subSnap = await subsRef.get();
      setCheckingStatus(false);
      setAuthedUser(userState);
      if (!subSnap.exists) {
        const validRef = await checkRef();
        if (validRef) {
          userState.refFrom = refToken;
        }
      } else {
        const sub = subSnap.data();
        if (sub.periodEnd < new Date().getTime()) {
          setSubExpired(new Date(sub.periodEnd));
        } else {
          window.location.href = "/dashboard";
        }
      }
    }
  };
  const checkRef = async () => {
    if (refToken) {
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        `${HOST}/verifyRefToken`,
        {
          refToken,
        },
        { headers: { authorization: "Bearer " + token } }
      );
      if (response.data.validReferal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onPlanClicked = (sku) => {
    setSku(sku);
  };
  const signInSuccessCallBack = (authResult) => {
    checkUser(authResult.user);
  };
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authedUser) => {
      if (authedUser) {
        checkUser(authedUser);
      }
    });
    if (window?.Stripe) {
      setStripe(window.Stripe(stripeAPIKEY));
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        setStripe(window.Stripe(stripeAPIKEY));
      });
    }
    // return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // const [firebaseuiLoaded, setFirebaseuiLoaded] = useState(false);
  // const [stripe, setStripe] = useState(false);
  // const [checkingStatus, setCheckingStatus] = useState(false);
  // const [authedUser, setAuthedUser] = useState(null);
  // const [subExpired, setSubExpired] = useState(null);
  // const [sku, setSku] = useState(
  //   window.location.search.match(/sku=/) &&
  //     window.location.search.replace(/\?sku=/, "")
  // );
  // const refToken = window.localStorage.getItem("ref");

  // if (!authedUser) {
  // const firebaseui = require("firebaseui");
  // const uiConfig = {
  //   signInOptions: [
  //     firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //     // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //     firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  //     // firebase.auth.TwitterAuthProvider.PROVIDER_ID
  //   ],
  //   callbacks: {
  //     signInSuccessWithAuthResult: (authResult) => {
  //       signInSuccessCallBack(authResult);
  //       return false;
  //     },
  //     uiShown: () => {
  //       setFirebaseuiLoaded(true);
  //     },
  //   },
  //   signInFlow: "popup",
  //   tosUrl: "/terms",
  //   credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  //   privacyPolicyUrl: function () {
  //     window.location.assign("/privacy");
  //   },
  // };
  // const ui =
  //   firebaseui.auth.AuthUI.getInstance() ||
  //   new firebaseui.auth.AuthUI(firebase.auth());
  // ui.start("#firebaseui-auth-container", uiConfig);
  // }

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((authedUser) => {
  //     if (authedUser) {
  //       checkUser(authedUser);
  //     }
  //   });
  //   if (window.Stripe) {
  //     setStripe(window.Stripe(stripeAPIKEY));
  //   } else {
  //     document.querySelector("#stripe-js").addEventListener("load", () => {
  //       setStripe(window.Stripe(stripeAPIKEY));
  //     });
  //   }
  // }, []);

  // const signInSuccessCallBack = (authResult) => {
  //   checkUser(authResult.user);
  // };

  // const checkRef = async () => {
  //   if (refToken) {
  //     const token = await firebase.auth().currentUser.getIdToken();
  //     const response = await axios.post(
  //       `${HOST}/verifyRefToken`,
  //       {
  //         refToken,
  //       },
  //       { headers: { authorization: "Bearer " + token } }
  //     );
  //     if (response.data.validReferal) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // const onPlanClicked = (sku) => {
  //   setSku(sku);
  // };

  // const checkUser = async (user) => {
  //   setCheckingStatus(true);
  //   const usersRef = firestore.collection("users").doc(user.uid);
  //   const subsRef = firestore.collection("subscriptions").doc(user.uid);

  //   const userState = {
  //     id: user.uid,
  //     name: user.displayName,
  //     email: user.email,
  //     photoUrl: user.photoURL,
  //     emailVerified: user.emailVerified,
  //   };
  //   const userSnap = await usersRef.get();

  //   if (!userSnap.exists) {
  //     const validRef = await checkRef();
  //     if (validRef) {
  //       userState.refFrom = refToken;
  //     }
  //     await usersRef.set(userState, { merge: true });
  //     setCheckingStatus(false);
  //     console.log("1");
  //     setAuthedUser(userState);
  //   } else {
  //     const subSnap = await subsRef.get();
  //     setCheckingStatus(false);
  //     console.log("2");
  //     setAuthedUser(userState);
  //     if (!subSnap.exists) {
  //       const validRef = await checkRef();
  //       if (validRef) {
  //         userState.refFrom = refToken;
  //       }
  //     } else {
  //       const sub = subSnap.data();
  //       if (sub.periodEnd < new Date().getTime()) {
  //         setSubExpired(new Date(sub.periodEnd));
  //       } else {
  //         window.location.href = "/dashboard";
  //       }
  //     }
  //   }
  // };

  const showDiscount = authedUser && authedUser?.refFrom;
  return authedUser ? (
    <div>
      <nav class="navbar navbar-light bg-light mb-4">
        <a class="navbar-brand" href="https://www.medrevisions.com">
          <img src={logo} style={{ height: "40px" }} alt="" />
        </a>
        <a
          class="nav-link i-false c-pointer dropdown-toggle"
          aria-haspopup="true"
          aria-expanded="false"
          to="#"
          variant=""
        >
          <div class="d-flex align-items-center ">
            <div class="header-info mr-2">
              <span class="text-black">
                Hello,{" "}
                <strong>{firebase?.auth()?.currentUser?.displayName}</strong>
              </span>
            </div>
            <div class="d-flex align-items-center">
              <a class="btn btn-outline-info rounded-0 mr-1 mb-1" href="/home">
                Dashboard
              </a>
              <button
                onClick={() => {
                  firebase
                    .auth()
                    .signOut()
                    .then((v) => {
                      window.location = "https://www.medrevisions.com";
                    });
                }}
                class="btn btn-outline-info rounded-0 mb-1"
              >
                Logout
              </button>
            </div>
          </div>
        </a>
      </nav>

      {checkingStatus ? (
        <div>
          <h5 variant="subtitle1" color="textSecondary">
            Checking for subscriptions...
          </h5>
          <LinearProgress color="secondary" />
        </div>
      ) : sku ? (
        <StripeProvider stripe={stripe}>
          <Elements>
            <Checkout user={authedUser} sku={sku} />
          </Elements>
        </StripeProvider>
      ) : (
        // <AsyncStripeProvider apiKey={stripeAPIKEY}>
        //   <Elements>
        //     <Checkout user={authedUser} sku={sku} />
        //   </Elements>
        // </AsyncStripeProvider>
        <>
          <div className="d-flex align-items-center justify-content-center p-4 flex-column">
            <h4>
              {subExpired
                ? "Select a plan to renew your membership"
                : "Pick a plan to get started"}
            </h4>
            <a className="btn btn-warning " href={`/demo/plab`}>
              Take a Demo For Free
            </a>
          </div>

          {subExpired && (
            <div className=" text-center">
              <h4 className="text-error text-center">
                Your subscription was expired on{" "}
                {subExpired.toLocaleDateString()}{" "}
                {subExpired.toLocaleTimeString()}. Please select a plan to renew
                your subscription.
              </h4>
              <a className="btn btn-warning mb-4" href={`/referral`}>
                Go to referral
              </a>
            </div>
          )}

          <PricingGrid
            showDiscount={showDiscount}
            onPlanClicked={onPlanClicked}
          />
        </>
      )}
    </div>
  ) : (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center p-4 flex-column">
              <a class="navbar-brand" href="https://www.medrevisions.com">
                <img
                  style={{ height: "40px" }}
                  src={logo}
                  alt=""
                  className="mb-4"
                />
              </a>
              <h5 className="text-center my-2">
                You can create an account or sign in using an email address or a
                social account
              </h5>
            </div>
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    {!firebaseuiLoaded && !sku && (
                      <LinearProgress color="secondary" />
                    )}
                    <StyledFirebaseAuth
                      uiConfig={uiConfig}
                      firebaseAuth={firebase.auth()}
                      uiCallback={(ui) => ui.disableAutoSignIn()}
                    />
                    {/* 
                        <div className="new-account mt-3">
                          <p>
                            Don't have an account?{" "}
                            <Link className="text-primary" to="/page-register">
                              Sign up
                            </Link>
                          </p>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
