import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { Table, Badge, Alert } from "react-bootstrap";
import { FormControl, FormHelperText } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import { Popover } from "react-tiny-popover";
import { firestore, firebase } from "../../../firebase";
import paidIcon from "../../../paid.svg";
import AuthContext from "../../../context";
const HOST = " https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

const Exams = (props) => {
  const { userType } = useContext(AuthContext);
  const [user, setUser] = useState({});
  const [totalQuestionCount, setTotalQuestionCount] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverId, setPopoverId] = useState("");
  useEffect(() => {
    if (userType !== "") {
      if (userType !== "demo_user" || userType === "expaird_user") {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            const userSnap = await firestore
              .collection("users")
              .doc(user.uid)
              .get();

            setUser({
              ...userSnap.data(),
              id: userSnap.id,
            });
            fetchTopics();
          } else {
            // User not logged in or has just logged out.
          }
        });
      }
    }
  }, []);
  const fetchTopics = () => {
    firestore
      .collection("topics")
      .where("exam", "==", "plab")
      .get()
      .then((snapShot) => {
        let questionCount = 0;
        snapShot.forEach((topic) => {
          const data = topic.data();
          questionCount += Object.keys(data.questions || {}).length;
        });
        setTotalQuestionCount(questionCount);
      })
      .catch((e) => {
        console.error(e);
        // window.location.href = "/auth";
      });
  };

  const { id } = user;
  const availableQuestions = totalQuestionCount;
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(180);
  const [exams, setExams] = useState([]);
  const [questionCount, setQuestionCount] = useState(180);
  const [usedQuestions, setUsedQuestions] = useState(0);
  const [recallsOnly, setRecallsOnly] = useState(false);

  const startExam = async (e) => {
    e.preventDefault();
    if (questionCount < 1 || questionCount > availableQuestions - usedQuestions)
      return;
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken();
    const examResponse = await axios.post(
      `${HOST}/createTimedExam`,
      {
        time,
        questionCount,
        recallsOnly,
      },
      { headers: { authorization: "Bearer " + token } }
    );
    setLoading(false);
    window.location.href = `/exam/${examResponse.data.id}`;
  };

  const fetchExams = () => {
    let usedQs = 0;
    if (id) {
      firestore
        .collection("timedExams")
        .where("user", "==", id)
        .onSnapshot((examsSpap) => {
          const tmpExams = [];
          examsSpap.forEach((examSnap) => {
            const examData = examSnap.data();

            // const subtitle2 = examData?.pause ? "Paused" : "";
            /* let subtitle = examData.startedAt
              ? Date.now() / 1000 - examData.startedAt.seconds >
                  examData.time * 60 || examData.ended
                ? "Ended"
                : new Date(examData.startedAt.seconds * 1000).toDateString()
              : "Not Started";
            subtitle =
              subtitle !== "Ended" && examData?.pause ? "Paused" : subtitle; */
            let subtitle=examData.ended?"Ended":examData.startedAt?examData?.pause?"Paused":Date.now() / 1000 - examData.startedAt.seconds > examData.time * 60?"Ended":new Date(examData.startedAt.seconds * 1000).toDateString():"Not Started";
            const _score = examData?.score || 0;
            const _totalQs = examData?.questions?.length;

            const _percentage = Math.floor((_score / _totalQs) * 100);

            const _final_score = `${_score} of ${_totalQs} (${_percentage}%)`;
            // Score: {score} of {exam.questions.length} - (
            //   {Math.floor((score / exam.questions.length) * 100)}%)

            const exam = {
              archived: examData.archived,
              createdAt: examData.createdAt.seconds,
              title: `${examData.questions.length} Questions in ${examData.time} Min`,
              // subtitle: `${subtitle}${
              //   examData.archived ? " - Archived" : ""
              // } - ${subtitle2}`,
              subtitle: subtitle,
              score: _final_score,
              on: "",
              // subtitle2: subtitle2,
            };
            if (!examData.archived) {
              usedQs = usedQs += examData.questions.length;
            }
            tmpExams.push({ ...exam, id: examSnap.id });
          });
          const sorted = tmpExams.sort((a, b) => {
            return b.createdAt - a.createdAt;
          });

          setExams(sorted);
          setUsedQuestions(usedQs);
        });
    }
  };

  const setArchiveExam = (examId, archived) => {
    setLoading(true);
    firestore
      .collection("timedExams")
      .doc(examId)
      .set({ archived }, { merge: true })
      .then(() => {
        fetchExams();
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userType !== "") {
      if (userType !== "expaird_user" || userType !== "demo_user") {
        fetchExams();
      }
    }
  }, [id]);
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-body">
            {userType === "demo_user" || userType === "expaird_user" ? (
              <div
                className="d-flex align-items-center justify-content-center flex-column p-2"
                // style={{ height: "50vh" }}
              >
                <img src={paidIcon} />
                <p className="mt-4">
                  This feature is disabled in the demo. You need to sign up for
                  a plan to access this.
                </p>
                <a
                  type="submit"
                  className="btn btn-warning  btn-rounded btn-sm mt-2"
                  href={`/auth#pricing`}
                >
                  {" "}
                  Subscribe to a plan
                </a>
                <a
                  type="submit"
                  className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                  href={`/demo/plab`}
                >
                  {" "}
                  See sample questions
                </a>
              </div>
            ) : (
              <>
                <div className="card-header">
                  <h4 className="card-title">Create new mock exam</h4>
                </div>
                {usedQuestions > 0 && (
                  <Alert
                    variant="info"
                    className="alert-alt alert-dismissible fade show"
                  >
                    There are{" "}
                    <strong>{availableQuestions - usedQuestions}</strong> of{" "}
                    {availableQuestions} questions remaining to create new mock
                    exams. Archive past mock exams to reuse questions from them.{" "}
                  </Alert>
                )}
                <div className="basic-form">
                  <form onSubmit={startExam}>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label>Question count</label>
                        <input
                          requird
                          type="number"
                          className="form-control"
                          placeholder="Question count"
                          disabled={loading}
                          label="Question count"
                          value={questionCount}
                          id="val-username1"
                          onChange={(e) => {
                            setQuestionCount(e.target.value);
                          }}
                        />
                        {!loading
                          ? questionCount < 1 ||
                            (questionCount >
                              availableQuestions - usedQuestions && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                please enter less than or equal to
                                {availableQuestions - usedQuestions}
                              </div>
                            ))
                          : null}
                      </div>
                      <div className="form-group col-md-3">
                        <label>Time in minutes</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Time in minutes"
                          required
                          disabled={loading}
                          value={time}
                          onChange={(e) => {
                            setTime(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group col-md-3 d-flex align-items-center justify-content-center">
                        {/* <FormControl>
                          <FormHelperText></FormHelperText>
                          <label>Recalls/High-yield only</label>
                          <Checkbox
                            checked={recallsOnly}
                            onChange={(e) => {
                              setRecallsOnly(e.target.checked);
                            }}
                            color="primary"
                          />
                        </FormControl> */}
                        <h5
                          onClick={() => setRecallsOnly(!recallsOnly)}
                          class={`d-flex align-items-center  m-0 py-2 question_answer ${
                            recallsOnly ? "text-info" : "text-dark"
                          }`}
                        >
                          {recallsOnly ? (
                            <i class="las la-check-square la-lg  mr-1"></i>
                          ) : (
                            <i class="la la-stop la-lg  mr-1"></i>
                          )}
                          Recalls/High-yield only
                        </h5>
                      </div>
                      <div className="form-group col-md-3 d-flex align-items-center justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-outline-info  btn-rounded btn-sm"
                          disabled={
                            loading ||
                            questionCount < 1 ||
                            questionCount > availableQuestions - usedQuestions
                          }
                        >
                          {loading ? (
                            <i
                              class="fa fa-circle-o-notch fa-spin fa-1x fa-fw "
                              style={{ color: "#2b98d6" }}
                            ></i>
                          ) : (
                            "Start Exam"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {userType !== "demo_user" || userType !== "expaird_user" ? (
        <div className="card">
          <div className="card-body">
            <div className="card-header">
              <h4 className="card-title">Past Exams</h4>
            </div>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Score</th>
                  <th>Archived</th>
                </tr>
              </thead>
              <tbody>
                {exams.map(
                  (exam) =>
                    !exam.archived && (
                      <tr
                        className="c-pointer"
                        key={exam.id}
                        onClick={() => {
                          window.location = `/exam/${exam.id}`;
                        }}
                      >
                        <td>
                          <div className="d-flex  justify-content-between flex-column">
                            <h6>{exam.title}</h6>
                            <p className="m-0">
                              {" "}
                              <i
                                class={`fa fa-circle ${
                                  exam.subtitle === "Ended"
                                    ? "text-success"
                                    : exam.subtitle === "Paused"
                                    ? "text-warning"
                                    : "text-info"
                                }  mr-1`}
                              ></i>{" "}
                              {exam.subtitle}
                            </p>
                          </div>
                        </td>
                        <td>
                          <Badge variant="info light">{exam.score}</Badge>
                        </td>
                        <td>
                          {!exam.archived && (
                            <Popover
                              isOpen={isPopoverOpen && popoverId === exam.id}
                              // positions={["bottom", "left"]}
                              content={
                                <div className="bg-white shadow border-danger p-4 border c-pointer">
                                  Are sure you want to Archive this exam? Once
                                  you archived you will not able to retrieve it
                                  <div className="d-flex align-items-center justify-content-between mt-4">
                                    <div
                                      className="btn tp-btn-light "
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setIsPopoverOpen(!isPopoverOpen);
                                        setPopoverId("");
                                      }}
                                    >
                                      cancel
                                    </div>
                                    <div
                                      className="btn btn-danger btn-sm"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setArchiveExam(exam.id, true);
                                        setPopoverId("");
                                        setIsPopoverOpen(!isPopoverOpen);
                                      }}
                                    >
                                      confirm
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsPopoverOpen(!isPopoverOpen);
                                  if (!popoverId) {
                                    setPopoverId(exam.id);
                                  } else {
                                    setPopoverId("");
                                  }
                                }}
                                type="button"
                                class="btn tp-btn-light btn-warning"
                              >
                                <i className="flaticon-381-archive"></i>
                              </button>
                            </Popover>
                          )}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Exams;
