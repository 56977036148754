import React, { useEffect, useState, useContext } from "react";
import { Link } from "@material-ui/core";
import { getRevisionQuestions } from "../../../util";

import { firestore, firebase } from "../../../firebase";
import swal from "sweetalert";
import { Alert } from "react-bootstrap";
import paidIcon from "../../../paid.svg";
import AuthContext from "../../../context";
const Revision = (props) => {
  const { userType } = useContext(AuthContext);

  const [revisions, setRevisions] = useState({
    inQueue: [],
    completed: [],
    one: [],
    seven: [],
    sixTeen: [],
    thirtyFive: [],
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("revision")
          .doc(user.uid)
          .get()
          .then(async (revisionSnap) => {
            const revisionData = revisionSnap.data() || {};
            const revisionObj = getRevisionQuestions(revisionData.questions);
            setRevisions(revisionObj);
          });
      }
    });
  }, []);

  const revisionCount =
    revisions.one.length +
    revisions.seven.length +
    revisions.sixTeen.length +
    revisions.thirtyFive.length;

  return (
    <>
      <div className="card">
        <div className="card-header d-none d-sm-block d-xl-none d-md-none">
          <h4 className="card-title"> Smart Revision</h4>
        </div>
        <div className="card-body">
          {userType === "demo_user" || userType === "expaird_user" ? (
            <div
              className="d-flex align-items-center justify-content-center flex-column p-2"
              // style={{ height: "50vh" }}
            >
              <img src={paidIcon} />
              <p className="mt-4">
                {/* This feature is available for our paid users only. Please signup
                for a plan to access this features. */}
                This feature is disabled in the demo. You need to sign up for a
                plan to access this.
              </p>
              <a
                type="submit"
                className="btn btn-warning  btn-rounded btn-sm mt-2 mr-2"
                href={`/auth#pricing`}
              >
                {" "}
                Subscribe to a plan
              </a>
              <a
                type="submit"
                className="btn btn-outline-info  btn-rounded btn-sm mt-2"
                href={`/demo/plab`}
              >
                {" "}
                See sample questions
              </a>
            </div>
          ) : (
            <>
              <Alert
                variant="info"
                className="alert-alt alert-dismissible fade show"
              >
                Smart revision spaces out questions with specified intervals for
                optimal memory retention. Questions with wrong answers are
                automatically saved for revision. You can also save questions
                manually. Learn more about distributed learning{" "}
                <Link
                  href="https://escholarship.org/uc/item/0kp5q19x"
                  target="_blank"
                >
                  here
                </Link>
              </Alert>

              <div className="d-flex align-items-center justify-content-end">
                <button
                  className=" btn btn-outline-info btn-sm btn-rounded"
                  onClick={() =>
                    swal({
                      title: "What do you want to revise ?",
                      // text: "Are you want to Revise questions or Notes ?",
                      // icon: "info",
                      // dangerMode: true,
                      buttons: {
                        // confirm: {
                        //   text: "Questions",
                        //   value: 'questions',
                        //   visible: true,
                        //   className: "",
                        //   // closeModal: true,
                        // },
                        // cancel: {
                        //   text: "Notes",
                        //   value: 'notes',
                        //   visible: true,
                        //   className: "",
                        //   // closeModal: true,

                        // },
                        notes: {
                          text: "Notes",
                          value: "notes",
                          className: "bg-dark",
                        },
                        questions: {
                          text: "Questions",
                          value: "questions",
                          className: "bg-info",
                        },
                      },
                    }).then((v) => {
                      if (v === "questions") {
                        window.location = "/revision?show=questions";
                      } else if (v === "notes") {
                        window.location = "/revision?show=notes";
                      }
                    })
                  }
                  // href="/revision"
                  disabled={revisionCount === 0}
                >
                  Start Revision
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {userType !== "demo_user" || userType === "expaird_user" ? (
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <span className="mr-3 bgl-success text-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11.602 13.76l1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412zm.002-2.828l4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L7.364 18 1 11.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z"
                        fill="rgba(47,204,113,1)"
                      />
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">Completed </p>
                    <h4 className="mb-0"> {revisions.completed.length}</h4>
                    {/* <span className="badge badge-success">-3.5%</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-sm-12">
            <div className="widget-stat card">
              <div className="card-body p-4">
                <div className="media ai-icon">
                  <span className="mr-3 bgl-warning text-warning">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M20.083 15.2l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zm0-4.7l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm-7.569-9.191l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0zM12 3.332L5.887 7 12 10.668 18.113 7 12 3.332z"
                        fill="rgba(241,196,14,1)"
                      />
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1">In Queue </p>
                    <h4 className="mb-0">{revisions.inQueue.length}</h4>
                    {/* <span className="badge badge-warning">+3.5%</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <div className="widget-stat card">
              <div className="card-body  p-4">
                <div className="media ai-icon">
                  <span className="mr-3 bgl-danger text-danger">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-4a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                        fill="rgba(231,76,60,1)"
                      />
                    </svg>
                  </span>
                  <div className="media-body">
                    <p className="mb-1"> Revise Now</p>
                    <h4 className="mb-0">{revisionCount}</h4>
                    {/* <span className="badge badge-danger">-3.5%</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Revision;
