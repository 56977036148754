import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogContentText,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { firestore, firebase } from "../../../firebase";

const ReportModal = ({ open, onClose, question }) => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState("");

  const handleClose = () => {
    onClose(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      firestore
        .collection("feedback")
        .add({
          questionId: question.id,
          title: question.title,
          topic: question.topic,
          subTopic: question.subTopic,
          uid: user.uid,
          email: user.email,
          feedback,
          date: firebase.firestore.FieldValue.serverTimestamp(),
          to: to,
        })
        .then(() => {
          setLoading(false);
          onClose(true);
        });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Question Feedback"}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText>
            Thank you for taking the time to provide your feedback on this
            question. Please fill in the form below and submit
          </DialogContentText>
          <FormControl style={{ minWidth: "200px" }}>
            <InputLabel id="demo-simple-select-label">To</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            
              required
              value={to}
              fullWidth
              onChange={(e) => {
                setTo(e.target.value);
              }}
            >
              <MenuItem value={"question"}> Question</MenuItem>
              <MenuItem value={"answer"}>Answer</MenuItem>
              <MenuItem value={"revision_note"}>Revision Notes</MenuItem>
              <MenuItem value={"study_note"}>Study Notes</MenuItem>
              <MenuItem value={"explanation_note"}>Explanation Notes</MenuItem>
              
            </Select>
          </FormControl>
        <div className="my-4"></div>
            <TextField
              onChange={(e) => setFeedback(e.target.value)}
              required
              autoFocus
              label="Feedback"
              fullWidth
            />
            <FormHelperText>Please be detailed as possible</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" type="submit" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReportModal;
