import React, { useState, useEffect } from "react";

import { firestore, firebase } from "../../../firebase";

import {
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  setRef,
} from "@material-ui/core";

import Education from "@material-ui/icons/CastForEducation";
import Money from "@material-ui/icons/AttachMoney";
import Link from "@material-ui/icons/Link";
import { set } from "date-fns/esm";

function createData({ refFor, date, credit }) {
  return {
    email: refFor,
    time: new Date(date.seconds * 1000).toDateString(),
    amount: credit,
  };
}

function createPayoutData({
  payPalEmail,
  requestedOn,
  payoutProcessedOn,
  amount,
}) {
  return {
    requestedOn: new Date(requestedOn.seconds * 1000).toDateString(),
    payoutProcessedOn: payoutProcessedOn
      ? new Date(payoutProcessedOn.seconds * 1000).toDateString()
      : "Pending",
    payPalEmail,
    amount,
  };
}

const Profile = (props) => {
  const [dialog, setDialog] = useState(false);
  const [dialogExmReminder, setDialogExmReminder] = useState(false);
  const [credits, setCredits] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [payPalEmail, setPayPalEmail] = useState(null);
  const [payPalEmailConf, setPayPalEmailConf] = useState(null);
  const [examDate, setExmDate] = useState(null);
  const [studyessentialQuestionsOnly, setStudyessentialQuestionsOnly] = useState(false);

  const { name, photoUrl, refToken, id, studyessentialQsOnly } = props.user;
  const { skuName, periodEnd } = props.subscription || {};
  const expiresOn = new Date(periodEnd);
  const handleClose = () => {
    setDialog(false);
  };
  const handleCloseExmReminder = () => {
    setDialogExmReminder(false);
  };
  const fetchTrans = () => {
    firestore
      .collection("referrals")
      .doc(id)
      .collection("credits")
      .get()
      .then((snap) => {
        const credits = [];
        let bal = 0;
        snap.forEach((s) => {
          const data = s.data();
          credits.push(createData(data));
          bal += data.credit;
        });
        setCredits(credits);

        firestore
          .collection("referrals")
          .doc(id)
          .collection("payouts")
          .get()
          .then((snap) => {
            const payouts = [];
            snap.forEach((s) => {
              const data = s.data();
              payouts.push(createPayoutData(data));
              bal -= data.amount;
            });
            setPayouts(payouts);
            setBalance(bal);
          });
      });
  };

  const requestPayout = (e) => {
    e.preventDefault();
    setLoading(true);
    firestore
      .collection("referrals")
      .doc(id)
      .collection("payouts")
      .add({
        requestedOn: firebase.firestore.FieldValue.serverTimestamp(),
        amount: balance,
        payPalEmail,
      })
      .then(() => {
        setLoading(false);
        fetchTrans();
      });
  };
  const submitExmDate = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await firestore.collection("users").doc(user.uid).set(
          {
            examDate,
          },
          { merge: true }
        );
        alert("ok");
      } else {
        window.location = "/auth";
        // User not logged in or has just logged out.
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchTrans();
      setStudyessentialQuestionsOnly(studyessentialQsOnly || false);
    }
  }, [id]);

  const toggleStudyEssentialQs = async (checked) => {
    setStudyessentialQuestionsOnly(checked);
    await firestore
      .collection("users")
      .doc(id)
      .set({
        studyessentialQsOnly: checked
      }, { merge: true });
  };

  return (
    <>
      <h2 className="card-title">Hello {name}</h2>

      {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex px-0 justify-content-between">
          <strong>{skuName}</strong>
          <span className="mb-0">
            {"Expires on: " + expiresOn.toDateString()}
          </span>
        </li>

        <li className="list-group-item d-flex px-0 justify-content-between">
          <strong>Balance</strong>
          <span className="mb-0">
            {" "}
            {` - US$ ${(props.referal || {}).balance || 0}`}
          </span>
        </li>
        {/* <li className="list-group-item d-flex px-0 justify-content-between">
          <strong>Referral Account</strong>
          <span className="mb-0">
            <IconButton onClick={() => setDialog(true)}>
              <Link />
            </IconButton>
          </span>
        </li> */}
        <li className="list-group-item d-flex px-0 justify-content-between">
          <strong>Set My Exam Date</strong>
          <span className="mb-0">
            <IconButton onClick={() => setDialogExmReminder(true)}>
              <i class="las la-user-clock"></i>
            </IconButton>
          </span>
        </li>
       {/* <li className="list-group-item d-flex px-0 justify-content-between">
          <h4
            onClick={() =>
              toggleStudyEssentialQs(!studyessentialQuestionsOnly)
            }
            class={`d-flex align-items-center  m-0 py-2 question_answer ${studyessentialQuestionsOnly ? "text-info" : "text-dark"
              }`}
          >
            {studyessentialQuestionsOnly ? (
              <i class="las la-check-square la-lg  mr-1"></i>
            ) : (
              <i class="la la-stop la-lg  mr-1"></i>
            )}
            <strong>Study Essential Mode</strong>
          </h4>
        </li>*/}

       {/* <li className="list-group-item d-flex px-0 justify-content-between">
          <span className="mb-0"><strong>Study Essential Mode:</strong> Curated study questions and notes tailored for exam preparation. Access filtered and revised questions on potential exam topics for effective and focused learning</span>
        </li>*/}
      </ul>

      {/* <Typography variant="h6" gutterBottom align="left" color="primary">
        Profile
      </Typography>
      <Avatar src={photoUrl} className="avatar" />
      <Typography variant="h5">Hello {name}</Typography> */}

      {/* <List component="nav">
        <ListItem>
          <ListItemIcon>
            <Education />
          </ListItemIcon>
          <ListItemText
            primary="Subscription"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {skuName}
                </Typography>
                {" — Expires on: " + expiresOn.toDateString()}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Money />
          </ListItemIcon>
          <ListItemText
            primary="Referral Account"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  Balance:
                </Typography>
                {` - US$ ${(props.referal || {}).balance || 0}`}
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => setDialog(true)}>
              <Link />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List> */}

      {/* <Dialog open={dialog} onClose={handleClose}>
        <form onSubmit={requestPayout}>
          <DialogTitle>{"Referral Account"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>
                <>
                  <a href={`https://www.medrevisions.com/?ref=${refToken}`}>
                    Referral Link:
                    {` — https://www.medrevisions.com/?ref=${refToken}`}
                  </a>
                </>
              </strong>
              <br />
              <br />
              Below is a transection summary of your account
            </DialogContentText>
            <Typography variant="subtitle2">Referral Credits</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Credit Amount (US$)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {credits.map((row, r) => (
                  <TableRow key={r}>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.time}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Typography variant="subtitle2">Payouts</Typography>
            {payouts.length > 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell> Paypal Email</TableCell>
                    <TableCell>Requested On</TableCell>
                    <TableCell>Completed On</TableCell>
                    <TableCell align="right">Payout Amount (US$)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payouts.map((row, r) => (
                    <TableRow key={r}>
                      <TableCell component="th" scope="row">
                        {row.payPalEmail}
                      </TableCell>

                      <TableCell>{row.requestedOn}</TableCell>
                      <TableCell>{row.payoutProcessedOn}</TableCell>

                      <TableCell align="right">{row.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              "No payouts yet"
            )}

            <br />
            <Typography variant="h6">
              Paypal Account Information - Balance: US$ {balance}
            </Typography>
            <Typography variant="subtitle2">
              You can request a payout to your paypal account when you have a
              balance of greater than US$ 20
            </Typography>

            <TextField
              required
              label="PayPal Email"
              margin="normal"
              type="email"
              onChange={(e) => setPayPalEmail(e.target.value)}
            />
            <br />
            <TextField
              required
              label="Confirm PayPal Email"
              margin="normal"
              type="email"
              onChange={(e) => setPayPalEmailConf(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              disabled={
                payPalEmail === null ||
                payPalEmail !== payPalEmailConf ||
                balance < 20 ||
                loading
              }
              type="submit"
            >
              Transfer to Paypal
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
      <Dialog open={dialogExmReminder} onClose={handleCloseExmReminder}>
        <form onSubmit={submitExmDate}>
          <DialogTitle>{"Set Next Exam"}</DialogTitle>
          <DialogContent>
            {/* <TextField
              required
              label="PayPal Email"
              margin="normal"
              type="email"
              onChange={(e) => setPayPalEmail(e.target.value)}
            />
            <br />
            <TextField
              required
              label="Confirm PayPal Email"
              margin="normal"
              type="email"
              onChange={(e) => setPayPalEmailConf(e.target.value)}
            /> */}
            <TextField
              required
              id="datetime-local"
              label="Next exam"
              type="datetime-local"
              defaultValue={new Date().toISOString()}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setExmDate(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExmReminder} color="primary">
              Close
            </Button>
            <Button
              onClick={handleCloseExmReminder}
              color="primary"
              disabled={loading}
              type="submit"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default Profile;
