import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    const { match, location, history, study, show, setShow } = this.props;
    let path = window.location.pathname;
    path = path.split("/");

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {study && (
              <li className={`${"home" === path ? "mm-active" : ""}`}>
                <Link
                  className=" ai-icon"
                  onClick={() => setShow(!show)}
                  aria-expanded="true"
                >
                  <i class="las la-info la-lg info-setting" style={{
                    padding: 10,
                    backgroundColor: '#0f6faf20',
                    borderRadius: 20
                  }}></i>
                </Link>
              </li>
            )}
            <li className={`${"home" === path ? "mm-active" : ""}`}>
              <Link
                className=" ai-icon"
                //  to="#"
                to="/home"
                // onClick={() => this.props.onClickPath()}
                aria-expanded="false"
              >
                <i class="las la-home la-lg"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${path.includes("bookmark") || path.includes("bookmarks")
                ? "mm-active"
                : ""
                }`}
            >
              <Link
                className=" ai-icon"
                //  to="#"
                to="/bookmark"
                aria-expanded="false"
              // onClick={() => this.props.onClickPath()}
              >
                <i class="las la-bookmark"></i>
                <span className="nav-text">Bookmarks</span>
              </Link>
            </li>
            <li className={`${path.includes("questions") ? "mm-active" : ""}`}>
              <Link
                className=" ai-icon"
                to="/questions"
                aria-expanded="false"
              // onClick={() => this.props.onClickPath()}
              >
                {/* <i className="flaticon-381-quaver"></i> */}
                <i class="las la-question-circle"></i>
                <span className="nav-text">Questions</span>
              </Link>
            </li>
            {/* <li className={`${"revision" === path ? "mm-active" : ""}`}>
              <Link
                className=" ai-icon"
                aria-expanded="false"
                to="/revision"
              >
                <i className="flaticon-381-internet"></i>
                <span className="nav-text">Revisions</span>
              </Link>
            </li> */}
            <li className={`${path.includes("library") ? "mm-active" : ""}`}>
              <Link className=" ai-icon" aria-expanded="false" to="/library">
                <i class="las la-book"></i>
                <span className="nav-text">Library</span>
              </Link>
            </li>
            <li
              className={`${path.includes("smart-revision") ? "mm-active" : ""
                }`}
            >
              <Link
                className=" ai-icon"
                aria-expanded="false"
                to="/smart-revision"
              >
                <i class="las la-history"></i>
                <span className="nav-text"> Smart Revision</span>
              </Link>
            </li>
            <li className={`${path.includes("time-exam") ? "mm-active" : ""}`}>
              <Link className=" ai-icon" aria-expanded="false" to="/time-exam">
                <i class="las la-stopwatch"></i>
                <span className="nav-text"> Timed Exams</span>
              </Link>
            </li>
            {/*<li className={`${path.includes("mock-exam") ? "mm-active" : ""}`}>
              <Link className=" ai-icon" aria-expanded="false" to="/mock-exam">
                <i class="las la-file"></i>
                <span className="nav-text"> Mock Exams</span>
              </Link>
            </li>*/}
            {/*<li className={`${path.includes("tags") ? "mm-active" : ""}`}>
              <Link className=" ai-icon" aria-expanded="false" to="/tags">
                <i class="las la-tags"></i>
                <span className="nav-text"> Tags</span>
              </Link>
            </li>*/}
            <li className={`${path.includes("referral") ? "mm-active" : ""}`}>
              <Link className=" ai-icon" aria-expanded="false" to="/referral">
                <i class="las la-hand-holding-usd"></i>
                <span className="nav-text">Referral</span>
              </Link>
            </li>
          </MM>

          <div className="copyright mt-5">
            {/* <p>
              <strong>MedRevision</strong> © 2021 All Rights Reserved
            </p> */}
            {/* <p>
                     Made with <i className="fa fa-heart" /> by DexignZone
                  </p> */}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
