import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { CardElement, injectStripe } from "react-stripe-elements";
import React, { useEffect, useState } from "react";
import { firebase, firestore } from "../../../firebase";

import CheckIcon from "@material-ui/icons/CheckCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PayPalButton } from "react-paypal-button-v2";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import stripeLogo from "../../../images/stripe.png";
import wechatLogo from "./wechat.png";
import SuccessModal from "./success";

const stripePromise = loadStripe("pk_live_eipbFT2Cpqee5C2yYgNpIKKL00oNt4500B");
// const stripePromise = loadStripe("pk_test_1VQ3S26ChnRcDylLKgCGEC14008hyn27Xy");

const QRCode = require("qrcode.react");
// const HOST = "http://localhost:5001/medicalapp-ad2fc/us-central1";
const HOST = "https://us-central1-medicalapp-ad2fc.cloudfunctions.net";

const createOptions = () => {
  return {
    style: {
      base: {
        color: "currentColor",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const Checkout = ({ stripe, user, sku }) => {
  const [skuData, setSkuData] = useState({ attributes: {} });
  const [cardError, setCardError] = useState(null);
  const [cardHolderName, setCardHolderName] = useState();
  const [paymentError, setPaymentError] = useState(null); //TODO: show proper errors
  const [paymentDone, setPaymentDone] = useState(null);
  const [weChat, setWeChat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingPayPal, setProcessingPayPal] = useState(false);
  const [openSucessModal, setOpenSuccessModal] = React.useState(false);

  const handleCloseWechat = () => {
    setWeChat(null);
  };

  const getSkuDetails = async () => {
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.post(
      `${HOST}/getSkuDetails`,
      {
        sku,
      },
      { headers: { authorization: "Bearer " + token } }
    );
    setLoading(false);
    setSkuData(response.data.sku);
  };

  const createWeChatSource = async (finalPrice) => {
    setProcessingPayment(true);
    const result = await stripe.createSource({
      type: "wechat",
      amount: Math.floor(finalPrice * 100),
      currency: "usd",
      statement_descriptor: skuData.attributes.name,
      metadata: {
        sku,
        refToken: user?.refFrom,
        customerId: user.id,
      },
    });
    firestore
      .collection("subscriptions")
      .doc(user.id)
      .onSnapshot(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            setTimeout(() => {
              window.location.href = "/home";
            }, 1000);
          }
          setPaymentDone(docSnapshot.exists);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );

    if (result.error) {
      alert("Payment Failed. Contact customer care");
    } else {
      setWeChat(result.source.wechat);
    }
    setProcessingPayment(false);
  };

  useEffect(() => {
    getSkuDetails();
  }, []);

  const redirectToStripe = async () => {
    setLoading(true);
    const stripe = await stripePromise;
    const bearerToken = await firebase.auth().currentUser.getIdToken();

    const response = await axios.post(
      `${HOST}/createStripeSession`,
      {
        sku,
        refToken: user.refFrom,
      },
      { headers: { authorization: "Bearer " + bearerToken } }
    );
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.data,
    });

    if (error) {
      setPaymentError(error);
    }
  };
  const handleStripeToken = async ({ token, error }) => {
    if (error) {
      setPaymentError(error.message);
      setProcessingPayment(false);
    } else {
      const bearerToken = await firebase.auth().currentUser.getIdToken();
      try {
        await axios.post(
          `${HOST}/chargeCard`,
          {
            sku,
            token: token.id,
            refToken: user.refFrom,
          },
          { headers: { authorization: "Bearer " + bearerToken } }
        );
        setTimeout(() => {
          window.location.href = "/home";
        }, 1000);
        setPaymentDone(true);
      } catch (e) {
        setPaymentError("Payment Failed. Contact customer care");
        setProcessingPayment(false);
        alert("Payment Failed. Contact customer care");
      }
      setProcessingPayment(false);
    }
  };

  const payByCard = (evt) => {
    setProcessingPayment(true);
    evt.preventDefault();
    if (stripe && !cardError) {
      stripe
        .createToken({ name: cardHolderName || undefined })
        .then(handleStripeToken);
    }
  };

  const handleCardChange = ({ error }) => {
    if (error) {
      setCardError(error.message);
    } else {
      setCardError(null);
    }
  };
  const discount = user?.refFrom ? skuData.price * 0.1 : 0;
  const finalPrice = ((skuData.price - discount) / 100).toFixed(2);

  return (
    <>
      <SuccessModal open={openSucessModal} setOpen={setOpenSuccessModal} />
      <div className="  rounded p-4 shadow  container">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <h3>Checkout</h3>
              {loading ? (
                <div className="loading-container d-flex align-items-center justify-content-center flex-column">
                  <CircularProgress />
                  <Typography>Verifying purchase items</Typography>
                </div>
              ) : (
                <List>
                  <ListItem divider={!user?.refFrom}>
                    <ListItemText
                      primary={skuData.attributes.name}
                      secondary={(skuData.attributes.name || "").replace(
                        /^.*-\s/,
                        ""
                      )}
                    />
                    <ListItemSecondaryAction>
                      US$ {(skuData.price / 100).toFixed(2)}
                    </ListItemSecondaryAction>
                  </ListItem>
                  {user?.refFrom && (
                    <ListItem divider>
                      <ListItemText
                        primary="Referal Discount"
                        secondary="10% off the total price"
                      />
                      <ListItemSecondaryAction>
                        US$ {(discount / 100).toFixed(2)}
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="h6">Total</Typography>}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="h6">US$ {finalPrice}</Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}

              <div className="secure-payment mx-2 my-3">
                Secure payments provider:{" "}
                <img src={stripeLogo} style={{ width: "140px" }} />
              </div>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className="pay-button  my-3"
                disabled={processingPayment || paymentDone || loading}
                onClick={redirectToStripe}
              >
                Continue to Payment
              </Button>
          

              {/* <fieldset>
        <legend
          className="card-only"
          data-tid="elements_examples.form.pay_with_card"
        >
          Pay with card
        </legend>
        <form onSubmit={payByCard}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextField
                fullWidth
                label="Name on card"
                onChange={(e) => setCardHolderName(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                disabled
                fullWidth
                label="Email"
                value={user.email}
                margin="normal"
              />
            </Grid>
          </Grid>

          <br />
          <InputLabel className="input-label"> Card</InputLabel>
          <div className="card-wrapper">
            <CardElement {...createOptions()} onChange={handleCardChange} />
          </div>
          <FormHelperText className="error-message">
            {cardError || paymentError}
          </FormHelperText>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            className="pay-button"
            disabled={processingPayment || paymentDone || loading}
          >
            {processingPayment ? (
              <>
                <CircularProgress /> Processing Payment
              </>
            ) : paymentDone ? (
              "Payment sucsessful"
            ) : (
              `Pay US$ ${finalPrice}`
            )}
          </Button>
        </form>
      </fieldset> */}

              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Additional Payment Options</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="paypal-button">
                    {processingPayPal && (
                      <div className="processing-paypal">
                        <CircularProgress /> Processing Payment
                      </div>
                    )}
                    <PayPalButton
                      amount={finalPrice}
                      shippingPreference="NO_SHIPPING"
                      onError={(error) => {
                        alert(
                          `${error.toString()}, Payment Failed. Contact customer care`
                        );
                      }}
                      onApprove={(data, details) => {
                        setProcessingPayPal(true);
                        details.order.capture().then(() =>
                          firestore
                            .collection("subscriptions")
                            .doc(user.id)
                            .onSnapshot(function (doc) {
                              const data = doc.data();
                              if (data.periodEnd > data.periodStart) {
                                window.location.href = "/home";
                              }
                            })
                        );
                      }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              description: skuData.attributes.name,
                              custom_id: `${sku}___${user.id}`,
                              amount: {
                                value: finalPrice,
                              },
                            },
                          ],
                        });
                      }}
                      options={{
                        clientId:
                          "AR_M57webG0ZuuAA3hgJywUApglrgVYUEyqM7Wgc7OeCnnBKlF3g2k2WC48yGnupx-3Udq_ZoCwXKA82",
                      }}

                      onSuccess={(details, data) => {
                        
                        // window.location = `https://success.medrevisions.com/success?p_id=${data.orderID}`;
                        window.location =
                          "https://dashboard.medrevisions.com/success";
                      }}
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <fieldset>
                <legend
                  className="card-only"
                  data-tid="elements_examples.form.pay_with_card"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1rem",
                    fontWeight: "500",
                    /* margin-top: 27rem; */
                    padding: "1rem",
                  }}
                >
                  {/* WeChat Pay */}
                  <img src={wechatLogo} style={{ width: "140px" }} />
                </legend>
                <Button
                  onClick={() => createWeChatSource(finalPrice)}
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  className="pay-button"
                  disabled={processingPayment || paymentDone || loading}
                >
                  {processingPayment ? (
                    <>
                      <CircularProgress /> Processing Payment
                    </>
                  ) : paymentDone ? (
                    "Payment successful"
                  ) : (
                    `Pay US$ ${finalPrice} with WeChat`
                  )}
                </Button>
              </fieldset>

              <Dialog
                open={paymentDone || Boolean(weChat)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {paymentDone
                    ? "Payment Sucsessful"
                    : "Authorize the payment using the WeChat App"}
                </DialogTitle>
                <DialogContent className="wechat-dialog">
                  <DialogContentText id="alert-dialog-description">
                    {/* <img src={(weChat || {}).qr_code_url} /> */}
                    {weChat && !paymentDone && (
                      <QRCode value={weChat.qr_code_url} />
                    )}
                    {paymentDone && (
                      <>
                        <CheckIcon className="check-icon" />
                        <Typography>
                          Payment Sucsessful. Redirecting to Study Room
                        </Typography>
                      </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {paymentDone ? (
                    <Button
                      onClick={handleCloseWechat}
                      color="primary"
                      href="/dashboard"
                    >
                      Go to Study Room
                    </Button>
                  ) : (
                    <Button
                      onClick={handleCloseWechat}
                      color="primary"
                      disabled={paymentDone}
                    >
                      Cancel Payment
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default injectStripe(Checkout);
