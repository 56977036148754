import React, { Fragment, useEffect, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import { useLocation } from "react-router-dom";
import { firebase, firestore } from "../../../firebase";
import SeModal from "./seModal";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const { pathname } = useLocation();
  const [studyessentialQsOnly,setStudyessentialQsOnly]=useState(false);
  const [show,setShow]=useState(false);

  useEffect(()=>{
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {

          const observer = firestore
            .collection("users")
            .doc(user.uid)
            .onSnapshot(docSnapshot => {
              if (docSnapshot.exists) {
                var data = docSnapshot.data();
                setStudyessentialQsOnly(data.studyessentialQsOnly || false);
              }
            }, err => {
              console.log(`Encountered error: ${err}`);
            });
      }
    });
  },[])

  return (
    <Fragment>
      <NavHader study={studyessentialQsOnly}/>
      <SideBar
        onClick={() => onClick2()}
        onClick3={() => onClick3()}
        pathname={pathname}
        study={studyessentialQsOnly}
        show={show}
        setShow={setShow}
      />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      <SeModal show={show} setShow={()=>setShow(!show)}/>
    </Fragment>
  );
};

export default JobieNav;
